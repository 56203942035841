import React, { useState } from "react";

// Css
import css from "../styles/employees.module.scss";

// Material Icon
import AddIcon from "@mui/icons-material/Add";

// react Links
import { Link } from "react-router-dom";
import EmpolyeesDataBox from "../components/EmpolyeesDataBox";
import EmployeesTable from "../components/Tables/EmployeesTable";
const Employees = () => {
  const [dataAnimate, setDataAnimate] = useState(false);
  
  return (
    <>
      <div className={css.employees}>
        <div className={css.employees_wrapper}>
          {/* employees Top */}
          <div className={css.employees_top}>
            <div className={css.employees_left}>
              <h1>Employees</h1>
              <h5>
                <Link to="/dashboard">Dashboard</Link> / Employees
              </h5>
            </div>
            <div className={css.employees_right}>
              <button
                onClick={() => setDataAnimate(!dataAnimate)}
                className={`${css.DataChangeBtn} ${
                  dataAnimate ? css.DataChangeBtnActive : ""
                }`}
              >
                <div className={css.bar}></div>
                <div className={css.bar}></div>
                <div className={css.bar}></div>
              </button>
              <Link to="/employees/add">
                <span>
                  <AddIcon />
                </span>
                Add Employees
              </Link>
            </div>
          </div>
          {/* Table Here */}
          {dataAnimate ? <EmpolyeesDataBox /> : <EmployeesTable />}
        </div>
      </div>
    </>
  );
};

export default Employees;