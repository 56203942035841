import React, { useEffect } from "react";
import axios from "axios";
import { useState } from "react";

// Css
import css from "../styles/dashboard.module.scss";

// Material Icons
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import TrackChangesOutlinedIcon from "@mui/icons-material/TrackChangesOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
// React Charts
import { Doughnut, Line } from "react-chartjs-2";

// eslint-disable-next-line
import { Charts as ChartJS } from "chart.js/auto";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../redux/slice/UserSlice";
import loginUser from "../redux/actions/login";
import { taskSelector } from "../redux/slice/TaskSlice";
import {
  GetAllTasksCount,
  GetAllTasksCountForEmployee,
} from "../redux/actions/TaskAction";
const EmployessAvailability = [
  {
    icon: <GradingOutlinedIcon />,
    title: "Attendence",
    num: "400",
  },
  {
    icon: <TrackChangesOutlinedIcon />,
    title: "Late Coming",
    num: "17",
  },

  {
    icon: <FormatListBulletedOutlinedIcon />,
    title: "Absent",
    num: "06",
  },

  {
    icon: <Diversity3OutlinedIcon />,
    title: "Leave Apply",
    num: "14",
  },
];

const LineChatData = [
  {
    id: 1,
    month: "0 Jan",
    employess: 10,
  },
  {
    id: 2,
    month: "31 Jan",
    employess: 30,
  },
  {
    id: 3,
    month: "22 Feb",
    employess: 5,
  },
  {
    id: 4,
    month: "15 Mar",
    employess: 45,
  },
  {
    id: 5,
    month: "05 Apr",
    employess: 20,
  },
  {
    id: 6,
    month: "26 Apr",
    employess: 90,
  },
  {
    id: 7,
    month: "17 May",
    employess: 10,
  },
  {
    id: 8,
    month: "8 Jun",
    employess: 70,
  },
  {
    id: 9,
    month: "29 Jun",
    employess: 40,
  },
  {
    id: 10,
    month: "20 Jul",
    employess: 60,
  },
];

const Dashboard = () => {
  const dispatch = useDispatch();
  const [progrossData, setPrograssData] = useState([]);
  const [employessData, setEmployessData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [isDashboardData, setIsDashboardData] = useState("");

  // Redux
  const { userData } = useSelector(userSelector);
  const { getAllCount } = useSelector(taskSelector);
  const { getCountById } = useSelector(taskSelector);
  const role = userData.data?.user.roles.role_name;
  const Admin = role === "admin";
  const id = userData.data?.user.id;
  const allTaskCount = getAllCount.data?.count;
  const allTaskSum = getAllCount.data?.sum;
  console.log(isDashboardData, "301");
  console.log(getAllCount, "102");
  console.log(allTaskCount, "104");
  console.log(getCountById, "103");

  useEffect(() => {
    if (Admin) {
      dispatch(GetAllTasksCount());
    } else {
      dispatch(GetAllTasksCountForEmployee({ id }));
    }

    dispatch(loginUser());
  }, []);

  // Line Chart Start
  const LineChart = {
    labels: LineChatData.map((data) => data.month),
    datasets: [
      {
        label: "Employees Info",
        data: LineChatData.map((data) => data.employess),
        borderColor: [
          "rgba(75,192,192,1)",
          "#ecf0f1",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderWidth: 3,
      },
    ],
  };
  // Line Chart End

  // Apis Start
  const dashboardData = async () => {
    try {
      const response = await axios.get("http://localhost:8080/dashboard");
      setIsDashboardData(response?.data?.data?.count);
    } catch (error) {
      console.log("");
    }
  };
  useEffect(() => {
    dashboardData();
  }, []);

  const ProgressBarApi = async () => {
    try {
      const response = await axios.get("http://localhost:3004/progessbar");
      setPrograssData(response.data);
    } catch (error) {
      console.log("");
    }
  };
  useEffect(() => {
    ProgressBarApi();
  }, []);

  // EmployessData Api
  const EmployessDataApi = async () => {
    try {
      const response = await axios.get("http://localhost:3004/EmployessData");
      setEmployessData(response.data);
    } catch (error) {
      console.log("");
    }
  };
  useEffect(() => {
    EmployessDataApi();
  }, []);

  // TaskData Api
  const TaskDataApi = async () => {
    try {
      const response = await axios.get("http://localhost:3004/TaskStatistics");
      setTaskData(response.data);
    } catch (error) {
      console.log("");
    }
  };
  useEffect(() => {
    TaskDataApi();
  }, []);

  const pieChart = {
    labels: ["Female", "Male"],
    datasets: [
      {
        label: "My First Dataset",
        data: [isDashboardData[1]?.value, isDashboardData[0]?.value],
        backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)"],
        hoverOffset: 4,
      },
    ],
  };

  // Apis End

  return (
    <div className={css.dashboard}>
      <div className={css.dashboard_wrapper}>
        {/* DashBoard Top Start */}
        {Admin && (
          <>
            <div className={css.employes}>
              <div className={css.employes_left}>
                <div className={css.line_chart}>
                  <Line data={LineChart} />
                </div>
              </div>
              <div className={css.employes_right}>
                <div className={css.employes_right_logo}>
                  <TaskOutlinedIcon />
                </div>
                <h3>{isDashboardData[2]?.value}</h3>
                <h5>Applications</h5>
              </div>
            </div>
            <div className={css.employes_boxs}>
              {/* Project */}
              <div className={css.employes_box}>
                <div className={css.employes_box_left}>
                  <GradingOutlinedIcon />
                </div>
                <div className={css.employes_box_right}>
                  <h4>{isDashboardData[4]?.value}</h4>
                  <h5>Project</h5>
                </div>
              </div>
              {/* Task */}
              <div className={css.employes_box}>
                <div className={css.employes_box_left}>
                  <FormatListBulletedOutlinedIcon />
                </div>
                <div className={css.employes_box_right}>
                  <h4>{isDashboardData[3]?.value}</h4>
                  <h5>Task</h5>
                </div>
              </div>
              {/* Admin */}
              <div className={css.employes_box}>
                <div className={css.employes_box_left}>
                  <SupervisorAccountIcon />
                </div>
                <div className={css.employes_box_right}>
                  <h4>{isDashboardData[6]?.value}</h4>
                  <h5>Admin</h5>
                </div>
              </div>
              {/* Employee */}
              <div className={css.employes_box}>
                <div className={css.employes_box_left}>
                  {" "}
                  <Diversity3OutlinedIcon />
                </div>
                <div className={css.employes_box_right}>
                  <h4>{isDashboardData[7]?.value}</h4>
                  <h5>Employee</h5>
                </div>
              </div>
            </div>
          </>
        )}
        {/* DashBoard Top End */}

        {/* DashBoard Bottom Start */}
        <div className={css.employes_detail}>
          <div className={css.employes_detailLeft}>
            <div className={css.employes_detailLeft_Wrapper}>
              <div className={css.detailLeft_heading}>
                <h3>Employees Avaigitlabity</h3>
              </div>
              <div className={css.detailLeft_boxs}>
                {EmployessAvailability.map((items, index) => {
                  return (
                    <>
                      <div className={css.detailLeft_box} key={index}>
                        <div className={css.detailLeft_logo}>{items.icon}</div>
                        <div className={css.detailLeft_Dec}>
                          <h5>{items.title}</h5>
                          <h3>{items.num}</h3>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          {Admin && (
            <div className={css.employes_detailRight}>
              <div className={css.PieChar}>
                {/* Donut Chart  */}
                <Doughnut data={pieChart} />
              </div>
            </div>
          )}
        </div>
        {/* DashBoard Bottom End */}

        {/* DashBoard Footer Start */}

        <div className={css.statistics}>
          <div className={css.statistics_left}>
            <div className={css.statistics_Wrapper}>
              <div className={css.statistics_heading}>
                <h3>Statistics</h3>
              </div>
              <div className={css.statistics_progressbars}>
                {progrossData?.map((items, i) => {
                  return (
                    <>
                      <div className={css.statistics_progressbar} key={i}>
                        <div className={css.statistics_progressbar_details}>
                          <span>{items.title}</span>
                          <span>{items.info}</span>
                        </div>
                        <div className={css.progessbar}>
                          <div className={css.bars}>
                            <div
                              className={css.bar}
                              style={{
                                backgroundColor: items.bg,
                                width: items.width,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={css.statistics_right}>
            <div className={css.statistics_Wrapper}>
              <div className={css.statistics_heading}>
                <h3>Task Statistics</h3>
              </div>
              <div className={css.taskBar}>
                <div className={css.taskbar_top}>
                  <div className={css.taskbar_top_left}>
                    <span>Total Tasks</span>
                    <span>{allTaskSum}</span>
                  </div>
                  <div className={css.taskbar_top_right}>
                    <span>Overdue Tasks</span>
                    <span>19</span>
                  </div>
                </div>
                <div className={css.taskbar_center}>
                  {allTaskCount?.map((items, i) => {
                    const Todo = items.key === "Todo";
                    const Block = items.key === "Block";
                    const Done = items.key === "Done";
                    const Intesting = items.key === "Intesting";
                    const Close = items.key === "Close";
                    const Inprogress = items.key === "Inprogress";
                    return (
                      <>
                        <div
                          key={i}
                          style={{
                            width: Math.round(100 * (items.value / allTaskSum)) + "%",
                            backgroundColor:
                              (Todo && "#ffa12b") ||
                              (Block && "#111") ||
                              (Done && "#283686") ||
                              (Intesting && "#ff05ff") ||
                              (Close && "#ff2018") ||
                              (Inprogress && "#2bb756"),
                          }}
                        >
                          <span style={{ fontSize: "0.9rem" }}>
                            {allTaskSum > 0
                              ? Math.round(100 * (items.value / allTaskSum)) + "%"
                              : "0%"}
                          </span>
                        </div>
                      </>
                    );
                  })}
                </div>
                {allTaskCount?.map((items, i) => {
                  const Todo = items.key === "Todo";
                  const Block = items.key === "Block";
                  const Done = items.key === "Done";
                  const Intesting = items.key === "Intesting";
                  const Close = items.key === "Close";
                  const Inprogress = items.key === "Inprogress";
                  return (
                    <>
                      <div className={css.taskbar_bottom} key={i}>
                        <div className={css.radio}>
                          <div
                            className={css.radio_btn}
                            style={{
                              border: `2px solid 
                            ${
                              (Todo && "#ffa12b") ||
                              (Block && "#111") ||
                              (Done && "#283686") ||
                              (Intesting && "#ff05ff") ||
                              (Close && "#ff2018") ||
                              (Inprogress && "#2bb756")
                            }
                            `,
                            }}
                          >
                            <div
                              className={css.btn}
                              style={{
                                backgroundColor:
                                  (Todo && "#ffa12b") ||
                                  (Block && "#111") ||
                                  (Done && "#283686") ||
                                  (Intesting && "#ff05ff") ||
                                  (Close && "#ff2018") ||
                                  (Inprogress && "#2bb756"),
                              }}
                            ></div>
                          </div>
                          <span>{items.key}</span>
                        </div>
                        <span>{items.value}</span>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/* DashBoard Footer End */}
      </div>
    </div>
  );
};

export default Dashboard;
