import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateData } from "../../config/AxiosFunction";
import api from "../../config/api_url";

export const ProjectUpdateAction = createAsyncThunk(
    "project/projectUpdateAction",
    async ({ id, Data }) => {
        const response = await updateData(`${api}projects/` + id, Data);
        return response;
    }
);

export default ProjectUpdateAction;
