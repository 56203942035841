
import { Navigate, useLocation, Outlet } from "react-router-dom";
import NewSidebar from "../components/NewSidebar";

const PrivateRoutes = (props) => {

  const { allowedRoles } = props;
  const location = useLocation();
  const auth = JSON.parse(localStorage.getItem("token"));
  return allowedRoles?.includes(auth?.role) ? (
    <NewSidebar> <Outlet /></NewSidebar>
  ) : auth?.accessToken ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}
export default PrivateRoutes;