import React from "react";

// Css
import css from "../styles/attendance.module.scss";

// react Links
import { Link } from "react-router-dom";

const Attendance = () => {
  return (
    <div className={css.attendance}>
      <div className={css.attendance_wrapper}>
        {/* Attendances Top  */}
        <div className={css.attendance_top}>
          <div className={css.attendance_left}>
            <h1>Attendance</h1>
            <h5>
              <Link to="/dashboard">Dashboard</Link> / Attendance
            </h5>
          </div>
          <div className={css.attendance_right}>
            {/* <a href="#">
                <span></span> Add Attendance
              </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attendance;
