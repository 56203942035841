import Axios from 'axios';
// import api from "../config/api_url";
// post request using fetch
// export const postRequest = async (api, body) => {
//     const res = await fetch(api, {
//         method: 'post',
//         headers: {
//             'Content-Type': 'application/json',
//             Authorization: localStorage.getItem('token'),
//         },
//         body: JSON.stringify(body),
//     });
//     return await res.json();
// };

// get request using fetch
// export const getRequest = async (api, type) => {
//     const res = await fetch(api, {
//         method: type,
//         headers: {
//             // Authorization: localStorage.getItem('token'),
//             "Content-Type": "application/json",
//             //   Accept: "*/*"
//         },
//     });
//     return await res.json();
// };
//   "Access-Control-Allow-Origin": "*"

// put request using fetch
// export const putRequest = async (api, body) => {
//     const res = await fetch(api, {
//         method: 'put',
//         headers: {
//             'Content-Type': 'application/json',
//             Authorization: localStorage.getItem('token'),
//         },

//         body: JSON.stringify(body),
//     });
//     return await res.json();
// };

// delete request using fetch
// export const deleteRequest = async (api, body) => {
//     const res = await fetch(api, {
//         method: 'delete',
//         headers: {
//             'Content-Type': 'application/json',
//             Authorization: localStorage.getItem('token'),
//         },

//         body: JSON.stringify(body),
//     });
//     return await res.json();
// };



// get request using Axios
export const getData = async (api, body) => {
    const res = await Axios.request({
        method: 'GET',
        url: api,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
        // params: body,
    });
    return await res.data;
};

// post request using Axios

export const postData = async (api, body) => {
    const responseData = await Axios.request({
        method: 'POST',
        url: api,
        // headers: {
        //     Authorization: localStorage.getItem('token'),
        // },
        data: body,
    });
    return await responseData.data;

};
export const taskPostData = async (api, body) => {
    const responseData = await Axios.request({
        method: 'POST',
        url: api,
        headers: {
            Authorization: localStorage.getItem('token'),
        },
        data: body,
    });
    return await responseData.data;

};
// export const postData = async (api, body) => {
//     const responseData = await Axios.request({
//         method: 'POST',
//         url: `${api}tasks/create`,
//         headers: {
//             Authorization: localStorage.getItem('token'),
//         },
//         data: body,
//     });
//     return await responseData.data;

// };

// const CreateTask = createAsyncThunk(
//     "tasks/create",
//     async ({ Data }) => {
//       const responseData = await postData(`${api}tasks/create`, Data)
//       return responseData;
//     });
// use for interceptor
// const postData = Axios.create({ baseURL: api })
// export const reqPostData = ({ ...option }) => {
//     postData.defaults.headers.common['Content-Type'] = "application/json"
//     const onSuccess = (response) => response
//     const onError = (error) => {
//         return error
//     }

//     return postData(option).then(onSuccess).catch(onError);

// }
// put request using Axios
export const updateData = async (api, body) => {
    const res = await Axios.request({
        method: 'PUT',
        url: api,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
        data: body,
    });
    return await res.data;
};
// delete request using Axios
// export const deleteData = async (api, body) => {
//     const res = await Axios.request({
//         method: 'DELETE',
//         url: api,
//         headers: {
//             'Content-Type': 'application/json',
//             Authorization: localStorage.getItem('token'),
//         },
//         data: body,
//     });
//     return await res.data;
// };