import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
// Css
import css from "../styles/projectDescription.module.scss";

// Material Images
import TodayIcon from "@mui/icons-material/Today";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import AssignmentIcon from "@mui/icons-material/Assignment";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { GetProject } from "../redux/actions/GetProjectsAction";
import { ProjectCreate } from "../redux/slice/ProjectSlice";
import DOMPurify from "dompurify";
import { useState } from "react";

const ProjectDescription = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [projectStatus, setProjectStatus] = useState("");
  const project = useSelector(ProjectCreate);
  const data = project.projectDataById.data?.project;

  const projectStartDate =
    new Date(data?.start_date).getDate() +
    "-" +
    new Date(data?.start_date).toLocaleString("en-us", {
      month: "short",
    }) +
    "-" +
    new Date(data?.start_date).getFullYear();

  const projectEndDate =
    new Date(data?.end_date).getDate() +
    "-" +
    new Date(data?.end_date).toLocaleString("en-us", {
      month: "short",
    }) +
    "-" +
    new Date(data?.end_date).getFullYear();

  let assignees = [];
  data?.users.map((item) =>
    assignees.push({
      label: item?.first_name + " " + item?.last_name,
      value: item?.id,
      key: item?.id,
    })
  );

  useEffect(() => {
    if (data?.status === "Completed") {
      return setProjectStatus("darkgreen");
    } else if (data?.status === "Ongoing") {
      return setProjectStatus("rgb(9, 202, 9)");
    } else if (data?.status === "Pending") {
      return setProjectStatus("gold");
    } else if (data?.status === "Canceled") {
      return setProjectStatus("red");
    }
  }, [data]);

  useEffect(() => {
    if (id) {
      dispatch(GetProject({id}));
    }
  }, []);

  return (
    <>
      <div className={css.projectDescription}>
        <div className={css.projectDescriptionWrapper}>
          <div className={css.projectDescriptionBox}>
            {/* Description Top */}
            <div className={css.projectDescriptionBox_Top}>
              <div className={css.projectDescriptionBox_Title}>
                <h4>{data?.project_name}</h4>
              </div>
              <div
                className={css.projectStatus}
                style={{ backgroundColor: projectStatus }}
              >
                <h4>
                  <span>Status :</span> {data?.status}
                </h4>
              </div>
            </div>
            {/* Project Date */}
            <div className={css.projectDescriptionBox_Bottom}>
              <div className={css.projectDescriptionBox_Detail_heading}>
                <h1>Project Date</h1>
              </div>
              <div className={css.projectDescriptionBox_Detail_Wrapper}>
                <div className={css.projectDescriptionBox_Detail_Date}>
                  <div className={css.projectDescriptionBox_Detail_Icon}>
                    <TodayIcon />
                  </div>
                  <div className={css.projectDescriptionBox_Detail_Item}>
                    <span>Start Date</span>
                    <h4>{projectStartDate}</h4>
                  </div>
                </div>
                <div className={css.projectDescriptionBox_Detail_Date}>
                  <div className={css.projectDescriptionBox_Detail_Icon}>
                    <InsertInvitationIcon />
                  </div>
                  <div className={css.projectDescriptionBox_Detail_Item}>
                    <span>End Date</span>
                    <h4>{projectEndDate}</h4>
                  </div>
                </div>
              </div>
            </div>
            {/* Project Assigne */}
            <div className={css.projectDescriptionBox_Bottom}>
              <div className={css.projectDescriptionBox_Detail_heading}>
                <h1>Project Assine</h1>
              </div>
              <div className={css.projectDescriptionBox_Detail_Wrapper}>
                <div className={css.projectDescriptionBox_Detail}>
                  <div className={css.projectDescriptionBox_Detail_Icon}>
                    <Diversity1Icon />
                  </div>
                  <div className={css.projectDescriptionBox_Detail_Item}>
                    <span>Assigne</span>
                    <div className={css.employeeDescriptionBox_Assigne}>
                      {assignees.map((assigne) => {
                        return (
                          <>
                            <h4>{assigne.label}</h4>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Project Description */}
            <div className={css.projectDescriptionBox_Bottom}>
              <div className={css.projectDescriptionBox_Detail_heading}>
                <h1>Project Description</h1>
              </div>
              <div className={css.projectDescriptionBox_Detail_Wrapper}>
                <div className={css.projectDescriptionBox_Detail}>
                  <div className={css.projectDescriptionBox_Detail_Icon}>
                    <AssignmentIcon />
                  </div>
                  <div className={css.projectDescriptionBox_Detail_Item}>
                    <span>Description</span>
                    <h4
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          data?.project_description
                        ),
                      }}
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectDescription;
