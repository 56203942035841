import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../config/api_url";
import { postData } from '../../config/AxiosFunction'

const AddProject = createAsyncThunk(
    "project/add",
    async ({ Data }) => {
        const responseData = await postData(`${api}projects`, Data)
        return responseData;
    });
export default AddProject;