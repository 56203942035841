import { createAction, createSlice } from "@reduxjs/toolkit";
import SignupUser from "../actions/signupAction";
import loginUser from "../actions/login";
import GetAllUser from "../actions/GetAllUsersAction";
import { ProfileUpdateAction } from "../actions/ProfileAction";

const initialState = {
  message: {},
  allUsers: {},
  userData: {},
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state, action) => {
      state = initialState;
      return state;
    },
  },

  extraReducers: {
    [SignupUser.fulfilled]: (state, { payload }) => {
      state.message = payload;
      return state;
    },
    [SignupUser.pending]: (state) => {
      state.isFetching = true;
    },
    [SignupUser.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },
    // loggin slice
    [loginUser.fulfilled]: (state, { payload }) => {
      state.userData = { ...payload };
      return state;
    },

    // Get All User Slice
    [GetAllUser.fulfilled]: (state, { payload }) => {
      state.allUsers = payload;
      return state;
    },
    [GetAllUser.pending]: (state) => {
      state.isFetching = true;
    },
    [GetAllUser.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },

    // Profile Update Action
    [ProfileUpdateAction.fulfilled]: (state, { payload }) => {
      const { userInfo } = payload.data;
      const {
        first_name,
        last_name,
        phone_no,
        gender,
        cnic,
        address,
        city,
        dob,
        marital_status,
        alt_phone_number,
        skills,
      } = userInfo;
      state.userData.data.user = {
        ...state.userData.data.user,
        first_name,
        last_name,
        phone_no,
        gender,
        cnic,
        address,
        city,
        dob,
        marital_status,
        alt_phone_number,
        skills,
      };
      return state;
    },

    [ProfileUpdateAction.pending]: (state) => {
      state.isFetching = true;
    },
    [ProfileUpdateAction.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },
  },
});
export const { logout } = userSlice.actions;
export const userSelector = (state) => state.user.valueState;
