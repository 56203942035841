import { createSlice } from "@reduxjs/toolkit";
import {
  AddTaskAction,
  GetAllTaskByProjectIdAction,
  GetAllTasks,
  GetAllTasksCount,
  GetAllTasksCountForEmployee,
  GetAllTasksForEmployee,
  GetCountById,
  getprojectByEmployeeId,
  GetTaskByIdAction,
  UpdateTaskAction,
} from "../actions/TaskAction";

export const TaskSlice = createSlice({
  name: "task",

  initialState: {
    message: {},
    getAllTask: {},
    getAllTaskById: {},
    getTaskById: {},
    getProjectForEmployee: {},
    getAllCount: {},
    getCountById: {},
    isFetching: false,
  },

  extraReducers: {
    // Get All Task
    [GetAllTasks.fulfilled]: (state, { payload }) => {
      state.getAllTask = payload;
      return state;
    },
    [GetAllTasks.pending]: (state) => {
      state.isFetching = true;
    },
    [GetAllTasks.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },

    [GetAllTasksForEmployee.fulfilled]: (state, { payload }) => {
      state.getAllTask = payload;
      return state;
    },
    [GetAllTasksForEmployee.pending]: (state) => {
      state.isFetching = true;
    },
    [GetAllTasksForEmployee.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },

    // Get All Task By project ID
    [GetAllTaskByProjectIdAction.fulfilled]: (state, { payload }) => {
      state.getAllTaskById = payload;
      return state;
    },
    [GetAllTaskByProjectIdAction.pending]: (state, { payload }) => {
      state.isFetching = true;
    },
    [GetAllTaskByProjectIdAction.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
    },

    // Get Task By Id
    [GetTaskByIdAction.fulfilled]: (state, { payload }) => {
      state.getTaskById = payload;
      return state;
    },
    [GetTaskByIdAction.pending]: (state, { payload }) => {
      state.isFetching = true;
    },
    [GetTaskByIdAction.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
    },

    // Get Project by Employee Id
    [getprojectByEmployeeId.fulfilled]: (state, { payload }) => {
      state.getProjectForEmployee = payload;
      return state;
    },
    [getprojectByEmployeeId.pending]: (state, { payload }) => {
      state.isFetching = true;
    },
    [getprojectByEmployeeId.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
    },

    // Count

    // Get All Task Count
    [GetAllTasksCount.fulfilled]: (state, { payload }) => {
      state.getAllCount = payload;
      return state;
    },
    [GetAllTasksCount.pending]: (state, { payload }) => {
      state.isFetching = true;
    },
    [GetAllTasksCount.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
    },

    // Get All Task Count For Employee

    [GetAllTasksCountForEmployee.fulfilled]: (state, { payload }) => {
      state.getAllCount = payload;
      return state;
    },
    [GetAllTasksCountForEmployee.pending]: (state, { payload }) => {
      state.isFetching = true;
    },
    [GetAllTasksCountForEmployee.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
    },

    // Get Count By Id

    [GetCountById.fulfilled]: (state, { payload }) => {
      state.getCountById = payload;
      return state;
    },
    [GetCountById.pending]: (state, { payload }) => {
      state.isFetching = true;
    },
    [GetCountById.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
    },
  },
});

export const taskSelector = (state) => state.task;
