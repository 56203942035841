import Profile from "../components/Profile";
import Dashboard from "../containers/Dashboard";
import Task from "../containers/Tickets";
import AddTask from "../components/AddTask";
import Attendance from "../containers/Attendance";
import Employees from "../containers/Employees";
import AddEmployees from "../components/AddEmployees";
import DisplayProject from "../containers/DisplayProject";
import AddProject from "../containers/Project";
import EmployeeDescription from "../components/EmployeeDescription";
import ProjectDescription from "../components/ProjectDescription";
import TaskDescription from "../components/TaskDescription";
import Notification from "../containers/Notification";

const RoutingPath = [
  // {
  //   path: "adminDashboard",
  //   Component: AdminDashboard,
  //   roles: ["admin"],
  // },
  {
    path: "dashboard",
    Component: Dashboard,
    roles: ["employee", "admin"],
  },
  {
    path: "profile",
    Component: Profile,
    roles: ["admin", "employee"],
  },
  {
    path: "employees",
    Component: Employees,
    roles: ["admin"],
  },
  {
    path: "employees/add",
    Component: AddEmployees,
    roles: ["admin"],
  },
  {
    path: "employees/description/:id",
    Component: EmployeeDescription,
    roles: ["admin"],
  },
  {
    path: "employees/edit/:id",
    Component: AddEmployees,
    roles: ["admin"],
  },

  {
    path: "task",
    Component: Task,
    roles: ["admin", "employee"],
  },

  {
    path: "task/add/:id",
    Component: AddTask,
    roles: ["employee", "admin"],
  },
  {
    path: "task/edit/:id",
    Component: AddTask,
    roles: ["admin", "employee"],
  },
  {
    path: "task/description/:id",
    Component: TaskDescription,
    roles: ["admin", "employee"],
  },
  {
    path: "attendance",
    Component: Attendance,
    roles: ["admin", "employee"],
  },
  {
    path: "projects",
    Component: DisplayProject,
    roles: ["admin"],
  },
  {
    path: "projects/add",
    Component: AddProject,
    roles: ["admin"],
  },
  {
    path: "projects/edit/:id",
    Component: AddProject,
    roles: ["admin"],
  },
  {
    path: "projects/description/:id",
    Component: ProjectDescription,
    roles: ["admin"],
  },
  {
    path: "notification",
    Component: Notification,
    roles: ["admin", "employee"],
  },
];
export default RoutingPath;
