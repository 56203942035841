import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

// Css
import css from "../styles/employeeDescription.module.scss";

// Material Icon
import Person2Icon from "@mui/icons-material/Person2";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import PhonePausedOutlinedIcon from "@mui/icons-material/PhonePausedOutlined";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import LocalFireDepartmentOutlinedIcon from "@mui/icons-material/LocalFireDepartmentOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import MilitaryTechOutlinedIcon from "@mui/icons-material/MilitaryTechOutlined";
import MemoryIcon from "@mui/icons-material/Memory";
import TransgenderIcon from '@mui/icons-material/Transgender';
// Redux
import { useState } from "react";
import axios from "axios";
import { UserShortName } from "../Utils";
import GoBack from "./GoBack";

const EmployeeDescription = () => {
  const { id } = useParams();
  const [employeeDescription, setEmplyeeDescription] = useState();
  const EmployeeGetById = async () => {
    try {
      const response = await axios.get(`http://localhost:8080/users/${id}`);
      const UpdateData = response?.data?.data;
      setEmplyeeDescription(UpdateData);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    EmployeeGetById();
  }, []);
  const Role = employeeDescription?.roles[0].role_name;
  const EmployeeDate =
    new Date(employeeDescription?.createdAt).getDate() +
    "-" +
    new Date(employeeDescription?.createdAt).toLocaleString("en-us", {
      month: "short",
    }) +
    "-" +
    new Date(employeeDescription?.createdAt).getFullYear();
  const newSkill = employeeDescription?.skills;

  console.log(employeeDescription , "101");

  return (
    <>
      <div className={css.employeeDescription}>
        <div className={css.employeeDescription_Wrapper}>
          <GoBack link={"/employees"} />
          <div className={css.employeeDescription_Box}>
            <div className={css.employeeDescriptionBox_Top}>
              <div className={css.employeeDescription_logo}>
                <div className={css.employeeDescription_Image}>
                  <h1>
                    <UserShortName
                      firstName={employeeDescription?.first_name}
                      lastName={employeeDescription?.last_name}
                    />
                  </h1>
                </div>
              </div>
              <div className={css.employeeDescription_Title}>
                <h2>{`${
                  employeeDescription?.first_name +
                  " " +
                  employeeDescription?.last_name
                }`}</h2>
                <h4>{Role}</h4>
                <button>
                  <Person2Icon />
                  Assign Project
                </button>
              </div>
              <div className={css.employeeDate}>
                <h4>
                  <span>Date :</span> {EmployeeDate}
                </h4>
              </div>
            </div>
            <div className={css.employeeDescriptionBox_Bottom}>
              <div className={css.employeeDescriptionBox_detail_heading}>
                <h1>Contact Information</h1>
              </div>
              <div className={css.employeeDescriptionBox_Detail_Wrapper}>
                <div className={css.employeeDescriptionBox_Detail}>
                  <div className={css.employeeDescriptionBox_Detail_Icon}>
                    <EmailOutlinedIcon />
                  </div>
                  <div className={css.employeeDescriptionBox_Detail_Item}>
                    <span>Email</span>
                    <h4>{employeeDescription?.email}</h4>
                  </div>
                </div>

                <div className={css.employeeDescriptionBox_Detail}>
                  <div className={css.employeeDescriptionBox_Detail_Icon}>
                    <LocalPhoneOutlinedIcon />
                  </div>
                  <div className={css.employeeDescriptionBox_Detail_Item}>
                    <span>Phone Number</span>
                    <h4>{employeeDescription?.phone_no}</h4>
                  </div>
                </div>

                <div className={css.employeeDescriptionBox_Detail}>
                  <div className={css.employeeDescriptionBox_Detail_Icon}>
                    <PhonePausedOutlinedIcon />
                  </div>
                  <div className={css.employeeDescriptionBox_Detail_Item}>
                    <span>Alt Phone Number</span>
                    <h4>{employeeDescription?.alt_phone_number}</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className={css.employeeDescriptionBox_Bottom}>
              <div className={css.employeeDescriptionBox_detail_heading}>
                <h1>Skills</h1>
              </div>
              <div className={css.employeeDescriptionBox_Detail_Wrapper}>
                <div className={css.employeeDescriptionBox_Skill}>
                  <div className={css.employeeDescriptionBox_Detail_Icon}>
                    <MemoryIcon />
                  </div>
                  <div className={css.employeeDescriptionBox_Detail_Item}>
                    <span>Skills</span>
                    <div className={css.employeeDescriptionBox_skills}>
                      {newSkill?.map((skills) => {
                        return (
                          <>
                            <h4>{skills}</h4>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={css.employeeDescriptionBox_Bottom}>
              <div className={css.employeeDescriptionBox_detail_heading}>
                <h1>Personal Information</h1>
              </div>
              <div className={css.employeeDescriptionBox_Detail_Wrapper}>
                <div className={css.employeeDescriptionBox_Detail}>
                  <div className={css.employeeDescriptionBox_Detail_Icon}>
                    <HomeOutlinedIcon />
                  </div>
                  <div className={css.employeeDescriptionBox_Detail_Item}>
                    <span>Address</span>
                    <h4>{employeeDescription?.address}</h4>
                  </div>
                </div>

                <div className={css.employeeDescriptionBox_Detail}>
                  <div className={css.employeeDescriptionBox_Detail_Icon}>
                    <LocationCityOutlinedIcon />
                  </div>
                  <div className={css.employeeDescriptionBox_Detail_Item}>
                    <span>City</span>
                    <h4>{employeeDescription?.city}</h4>
                  </div>
                </div>

                <div className={css.employeeDescriptionBox_Detail}>
                  <div className={css.employeeDescriptionBox_Detail_Icon}>
                    <CakeOutlinedIcon />
                  </div>
                  <div className={css.employeeDescriptionBox_Detail_Item}>
                    <span>Date of Birth</span>
                    <h4>{employeeDescription?.dob}</h4>
                  </div>
                </div>

                <div className={css.employeeDescriptionBox_Detail}>
                  <div className={css.employeeDescriptionBox_Detail_Icon}>
                    <LocalFireDepartmentOutlinedIcon />
                  </div>
                  <div className={css.employeeDescriptionBox_Detail_Item}>
                    <span>Material Status</span>
                    <h4>{employeeDescription?.marital_status} </h4>
                  </div>
                </div>

                <div className={css.employeeDescriptionBox_Detail}>
                  <div className={css.employeeDescriptionBox_Detail_Icon}>
                    <CreditCardOutlinedIcon />
                  </div>
                  <div className={css.employeeDescriptionBox_Detail_Item}>
                    <span>Cnic</span>
                    <h4>{employeeDescription?.cnic}</h4>
                  </div>
                </div>

                <div className={css.employeeDescriptionBox_Detail}>
                  <div className={css.employeeDescriptionBox_Detail_Icon}>
                    <MilitaryTechOutlinedIcon />
                  </div>
                  <div className={css.employeeDescriptionBox_Detail_Item}>
                    <span>Status</span>
                    <h4>{employeeDescription?.status}</h4>
                  </div>
                </div>

                <div className={css.employeeDescriptionBox_Detail}>
                  <div className={css.employeeDescriptionBox_Detail_Icon}>
                    <TransgenderIcon />
                  </div>
                  <div className={css.employeeDescriptionBox_Detail_Item}>
                    <span>Gender</span>
                    <h4>{employeeDescription?.gender}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeDescription;
