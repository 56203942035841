import React, { useEffect, useState } from "react";
import { Await, useNavigate } from "react-router-dom";

// Css
import css from "../../styles/employeesTable.module.scss";

// Material Icon
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import WidgetsIcon from "@mui/icons-material/Widgets";
import VisibilityIcon from "@mui/icons-material/Visibility";

// Table
import DataTable from "react-data-table-component";

// // Redux
import { useSelector, useDispatch } from "react-redux";
import { FilterEmployees } from "../../redux/actions/FilterAction";
import { GetEmployeeData } from "../../redux/actions/Employees";

//Search Component
import Filtering from "../Filtering";

const EmployesTable = () => {
  // const [tableData, setTableData] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employData = useSelector((state) => state.employee?.gettAllData);
  const Data = employData?.data?.users;
  const searchFilterData = useSelector(
    (state) => state.getFilterData?.FilterEmployee?.data?.users
  );
  // const [filterData, setFilterData] = useState();

  // fetch Data from filtering component
  const statuses = [["All", "Approved", "Pending", "Left"]];
  const [start_date, setStarting_Date] = useState("");
  const [end_date, setEnding_Date] = useState("");
  const [searchData, setSearchData] = useState("");
  const [status, setStatus] = useState({ statusType: "" });
  const placeholders = ["Select Status"];
  useEffect(() => {
    dispatch(
      FilterEmployees({
        status: status.statusType === "All" ? "" : status.statusType,
        searchData,
        start_date: start_date ? new Date(start_date) : "",
        end_date: end_date ? new Date(end_date) : "",
      })
    );
  }, [status, searchData, start_date, end_date]);

  // useEffect(() => {
  //   if (searchFilterData) {
  //     const result = setTableData(true);
  //     setFilterData(searchFilterData);
  //   }
  // }, [searchFilterData]);

  useEffect(() => {
    dispatch(GetEmployeeData());
  }, []);

  // Edit Employee
  const handleEditEmploy = (e) => {
    navigate(`edit/${e.id}`);
  };

  // Employee Description
  const handleDescription = (e) => {
    navigate(`description/${e.id}`);
  };

  // Employee Delete
  const [employDelete, setemployDelete] = useState(false);
  const handleDelete = () => {
    setemployDelete(true);
  };

  // Table Coloum
  const colums = [
    {
      cell: () => <WidgetsIcon style={{ fill: "#283686" }} />,
      sortable: false,
      width: "56px",
    },
    {
      name: "Full Name",
      selector: (row) => `${row.first_name} ${row.last_name}`,
      sortable: true,
      reorder: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
      width: "250px",
      style: {
        color: "#202124",
        fontSize: "15px",
        fontWeight: 600,
      },
    },
    {
      name: "Join Date",
      // selector: (row) => row.createdAt,
      selector: (row) =>
        new Date(row.createdAt).getDate() +
        "-" +
        new Date(row.createdAt).toLocaleString("en-us", { month: "short" }) +
        "-" +
        new Date(row.createdAt).getFullYear(),
      sortable: true,
      reorder: true,
    },
    {
      name: "Phone No",
      selector: (row) => row.phone_no,
      sortable: true,
      reorder: true,
    },

    {
      name: "Status",
      cell: (row) => {
        if (row.status === "Approved") {
          return <span className={css.tableStatusApproved}>{row.status}</span>;
        } else if (row.status === "Pending") {
          return <span className={css.tableStatusPending}>{row.status}</span>;
        } else {
          return <span className={css.tableStatusLeft}>{row.status}</span>;
        }
      },
      sortable: true,
      reorder: true,
      style: {
        fontWeight: 600,
      },
    },

    {
      name: "ACTIONS",
      reorder: true,
      center: true,
      cell: (row) => (
        <>
          <div className={css.tableIcon}>
            <VisibilityIcon onClick={(e) => handleDescription(row)} />
            <DeleteIcon onClick={handleDelete} />
            <EditIcon onClick={(e) => handleEditEmploy(row)} />
          </div>
        </>
      ),
    },
  ];
  // Table Styling
  const customStyles = {
    header: {
      style: {
        fontSize: "32px",
        color: "#283686",
      },
    },

    headRow: {
      style: {
        fontSize: "16px",
      },
    },
    headCells: {
      style: {
        color: "#3a3a3a",
        fontSize: "16px",
        fontWeight: "600",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        boxSizing: "inherit",
        borderBottomColor: "#FFFFFF",
        borderRadius: "25px",
        outline: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
        justifyContent: "flex-start",
      },
    },
  };
  return (
    <>
      <div className={css.task_Table}>
        {employData && (
          <DataTable
            // title="Employees Details"
            subHeader
            fixedHeader
            subHeaderComponent={
              <>
                <Filtering
                  setStarting_Date={setStarting_Date}
                  setEnding_Date={setEnding_Date}
                  setStatus={setStatus}
                  setSearchData={setSearchData}
                  status={status}
                  statuses={statuses}
                  placeholders={placeholders}
                />
              </>
            }
            fixedHeaderScrollHeight="500px"
            columns={colums}
            data={searchFilterData}
            customStyles={customStyles}
            pagination
            highlightOnHover
            pointerOnHover
          />
        )}
      </div>

      {/* <DeleteEmployee
        setemployDelete={setemployDelete}
        employDelete={employDelete}
      /> */}
    </>
  );
};

export default EmployesTable;
