import React, { useState } from "react";
import { useEffect } from "react";

// Css
import css from "../styles/addTask.module.scss";

// React Router
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

// Components
import Input from "./sharedComponents/input";
import SelectInput from "./sharedComponents/Select";

// Material Icon
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import HistoryToggleOffOutlinedIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import DevicesFoldOutlinedIcon from "@mui/icons-material/DevicesFoldOutlined";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import RichTextEditor from "./textarea_component/RichTextEditor";
import AdjustIcon from "@mui/icons-material/Adjust";
import CloseIcon from "@mui/icons-material/Close";

// React Select
import Select from "react-select";

// React hook from
import { useForm, Controller } from "react-hook-form";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { ProjectCreate } from "../redux/slice/ProjectSlice";
import {
  AddTaskAction,
  getprojectByEmployeeId,
  GetTaskByIdAction,
  UpdateTaskAction,
} from "../redux/actions/TaskAction";
import { taskSelector } from "../redux/slice/TaskSlice";
import GetAllUser from "../redux/actions/GetAllUsersAction";
import { GetProject } from "../redux/actions/GetProjectsAction";
import loginUser from "../redux/actions/login";
import { userSelector } from "../redux/slice/UserSlice";

const AddTask = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });
  let { id } = useParams();
  const [assigne, setAssigne] = useState();
  const [assigneByProject, setAssigneByProject] = useState();
  const [taskActualTime, settaskActualTime] = useState(false);
  const [projectOption, setProjectOption] = useState();

  const pathName = location.pathname.split("/").slice(1, -1).join("/");
  const AddTask = pathName === "task/add";
  const EditTask = pathName === "task/edit";
  // ----------------- Redux ------------ //
  const { userData } = useSelector(userSelector);
  // Project
  const { projectData } = useSelector(ProjectCreate);
  const { projectDataById } = useSelector(ProjectCreate);
  const { getTaskById } = useSelector(taskSelector);
  const { getProjectForEmployee } = useSelector(taskSelector);

  const projects = projectData.data?.project;
  const projectGetById = projectDataById?.data?.project;
  const taskDataById = getTaskById?.data;
  const projectId = taskDataById?.project_id;
  const projectsforEmployee = getProjectForEmployee?.data?.project;

  // User Data
  const role = userData.data?.user.roles.role_name;
  const userId = userData.data?.user.id;
  //---------------- Task Edit Start  -------------//
  useEffect(() => {
    if (EditTask) {
      dispatch(GetTaskByIdAction(id));
    }
  }, []);

  useEffect(() => {
    if (EditTask && pathName === "task/edit" && taskDataById) {
      const AAA = taskDataById.users.map(({ id, first_name, last_name }) => {
        return {
          id: id,
          option: first_name + " " + last_name,
        };
      });

      const fields = [
        "task_title",
        "task_description",
        "estimated_time",
        "actual_time",
      ];
      fields.forEach((field) => setValue(field, taskDataById[field]));
      setValue("Priority", {
        label: taskDataById.Priority,
        value: taskDataById.Priority,
      });
      setValue("status", {
        label: taskDataById.status,
        value: taskDataById.status,
      });
      setValue("Ticket_type", {
        label: taskDataById.Ticket_type,
        value: taskDataById.Ticket_type,
      });
      setValue("dev_Type", {
        label: taskDataById.dev_Type,
        value: taskDataById.dev_Type,
      });
      setValue("assignee", AAA[0]);
      console.log(AAA , "501");
    }
  }, [taskDataById]);
  //---------------- Task Edit End  -------------//
  useEffect(() => {
    if (id === "all" && AddTask) {
      if (role === "employee") {
        dispatch(getprojectByEmployeeId(userId));
      } else {
        dispatch(GetProject({ id }));
      }
    } else {
      dispatch(GetProject({ id }));
    }
  }, [id]);

  useEffect(() => {
    dispatch(loginUser());
  }, []);

  useEffect(() => {
    if (projectId) {
      dispatch(GetProject({ id: projectId }));
    }
  }, [projectId]);

  // Select Options
  const PriorityOption = [
    {
      label: "Normal",
      value: "Normal",
      bg: "#ffa12b",
      icon: <AdjustIcon />,
    },
    {
      label: "High",
      value: "High",
      bg: "#ff2018",
      icon: <AdjustIcon />,
    },
    {
      label: "Low",
      value: "Low",
      bg: "#2bb756",
      icon: <AdjustIcon />,
    },
  ];
  const TicketOption = [
    {
      label: "Spike",
      value: "Spike",
      bg: "#ffa12b",
      icon: <AdjustIcon />,
    },
    {
      label: "Bug",
      value: "Bug",
      bg: "#ff2018",
      icon: <AdjustIcon />,
    },
    {
      label: "Task",
      value: "Task",
      bg: "#2bb756",
      icon: <AdjustIcon />,
    },
  ];
  const DevTypeOption = [
    {
      label: "Frontend",
      value: "Frontend",
      bg: "#ffa12b",
      icon: <AdjustIcon />,
    },
    {
      label: "Backend",
      value: "Backend",
      bg: "#2bb756",
      icon: <AdjustIcon />,
    },
  ];
  let StatusOption = [
    {
      label: "Block",
      value: "Block",
      bg: "#111",
      icon: <AdjustIcon />,
    },
    {
      label: "Intesting",
      value: "Intesting",
      bg: "#ff05ff",
      icon: <AdjustIcon />,
    },

    {
      label: "Close",
      value: "Close",
      bg: "#ff2018",
      icon: <AdjustIcon />,
    },
    {
      label: "Inprogress",
      value: "Inprogress",
      bg: "#2bb756",
      icon: <AdjustIcon />,
    },
    {
      label: "Todo",
      value: "Todo",
      bg: "#ffa12b",
      icon: <AdjustIcon />,
    },
  ];
  if (taskDataById?.status === "Intesting") {
    const newObject = {
      label: "Done",
      value: "Done",
      bg: "#283686",
      icon: <AdjustIcon />,
    };
    StatusOption = [...StatusOption, newObject];
  }

  useEffect(() => {
    if (projectGetById) {
      const Assigne = projectGetById?.users.map(
        ({ id, first_name, last_name }) => {
          return {
            id: id,
            option: first_name + " " + last_name,
          };
        }
      );
      setAssigneByProject(Assigne);
    }
  }, [projectGetById]);

  useEffect(() => {
    let ProjectUser;
    if (role === "employee") {
      dispatch(GetProject({ id:assigne }));
    }
    ProjectUser = projects?.filter((data) => data.id === +assigne);
    if (id === "all" && ProjectUser && ProjectUser.length > 0) {
      const Users = ProjectUser[0].users;
      const Assigne = Users.map(({ id, first_name, last_name }) => {
        return {
          id: id,
          option: first_name + " " + last_name,
        };
      });
      setAssigneByProject(Assigne);
    }
  }, [assigne]);

  useEffect(() => {
    if (role === "employee") {
      const projectOption = projectsforEmployee?.map((item) => {
        return {
          id: item.id,
          option: item.project_name,
        };
      });
      setProjectOption(projectOption);
    } else {
      const projectOption = projects?.map((item) => {
        return {
          id: item.id,
          option: item.project_name,
        };
      });
      setProjectOption(projectOption);
    }
  }, [role]);

  console.log(id, "101");
  console.log(projects, "102");

  const onSubmit = (data) => {
    if (data) {
      const {
        task_title,
        task_description,
        Priority: { label: Priority },
        Ticket_type: { label: Ticket_type },
        dev_Type: { label: dev_Type },
        estimated_time,
        assignee,
        actual_time,
        ...rest
      } = data;
      const { label: status } = EditTask ? rest.status || {} : {};

      const newObject = {
        task_title,
        task_description,
        Priority,
        Ticket_type,
        dev_Type,
        status,
        estimated_time,
        actual_time,
        assignee,
      };
      const { project_id } = data;
      if (AddTask) {
        if (id === "all") {
          dispatch(AddTaskAction({ Data: newObject, id: project_id }));
          Navigate(`/task?project=all`);
        } else {
          dispatch(AddTaskAction({ Data: newObject, id }));
          Navigate(`/task?project=${id}`);
        }
      } else {
        dispatch(UpdateTaskAction({ Data: newObject, id }));
        Navigate(`/task?project=${projectId}`);
      }
    }
  };

  // Task Actual Time
  const handleTaskStatus = (e) => {
    if (e.value === "Intesting") {
      settaskActualTime(true);
    } else {
      settaskActualTime(false);
    }
  };

  useEffect(() => {
    if (taskDataById?.status === "Intesting") {
      settaskActualTime(true);
    } else {
      settaskActualTime(false);
    }
  }, [taskDataById]);

  const handleCancel = () => {
    Navigate(`/task?project=${id}`);
  };

  return (
    <>
      <div className={css.AddTask}>
        <div className={css.AddTask_Wrapper}>
          <div className={css.Task_title}>
            <div className={css.Task_title_left}>
              <h1>{AddTask ? "Add Task" : "Edit Task"}</h1>
              <h5>
                <Link to={`/task?project=all`}>Tasks</Link> /
                {AddTask ? "Add Task" : "Edit Task"}
              </h5>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={css.taskTop}>
              <div className={css.taskTop_wrapper}>
                <Input
                  placeholder="Task Title..."
                  label="task_title"
                  name="Task Title"
                  type="text"
                  register={register}
                />
              </div>
              <div className={css.taskTop_wrapper}>
                {id === "all" && (
                  <SelectInput
                    option={projectOption}
                    placeholder="Project Name..."
                    label="project_id"
                    name="project Name"
                    register={register}
                    onChange={(e) => setAssigne(e.target.value)}
                  />
                )}
              </div>
            </div>
            <div className={css.taskDescriptionBox}>
              <div className={css.task_TextEditer}>
                <Controller
                  name="task_description"
                  control={control}
                  render={({ field }) => {
                    let value = field.value ? field.value : "";
                    return (
                      <RichTextEditor
                        onChange={(e) =>
                          field.onChange(
                            e.html.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, "")
                          )
                        }
                        value={value}
                      />
                    );
                  }}
                />
              </div>
            </div>

            {/* -------------------Task Assignee ------------------- */}
            <div className={css.taskBox}>
              <div className={css.taskBox_Heading}>
                <h2>Task Assign :</h2>
              </div>
              <div className={css.task_DetailBoxs}>
                <div className={css.task_DetailBox}>
                  <h4>
                    <AssignmentIndOutlinedIcon /> Assignee:
                  </h4>
                  <SelectInput
                    placeholder="Assignee"
                    option={assigneByProject}
                    label="assignee"
                    name="Assignee"
                    register={register}
                  />
                </div>
                <div className={css.task_DetailBox}>
                  <h4>
                    <HistoryToggleOffOutlinedIcon /> Estimated Time:
                  </h4>
                  <Input
                    placeholder="Time"
                    label="estimated_time"
                    name="Estimated Time"
                    type="number"
                    register={register}
                  />
                </div>
              </div>
            </div>
            {/* -------------------Task Status ------------------- */}

            <div className={css.taskBox}>
              <div className={css.taskBox_Heading}>
                <h2>Task Status :</h2>
              </div>
              <div className={css.task_DetailBoxs}>
                <div className={css.task_DetailBox}>
                  <h4>
                    <AutoGraphOutlinedIcon /> Priority:
                  </h4>
                  <Controller
                    control={control}
                    name="Priority"
                    render={({ field }) => (
                      <Select
                        placeholder="Priority"
                        register={register}
                        label="Priority"
                        name="Priority"
                        onChange={(e) => field.onChange(e)}
                        selected={field.value}
                        value={field.value}
                        className={css.react_Select}
                        options={PriorityOption}
                        isSearchable={false}
                        getOptionLabel={(e) => (
                          <div className={css.selectItem}>
                            <span style={{ color: e.bg }}>{e.icon}</span>
                            {e.label}
                          </div>
                        )}
                      />
                    )}
                  />
                </div>
                <div className={css.task_DetailBox}>
                  {!AddTask && (
                    <>
                      <h4>
                        <LayersOutlinedIcon /> Status:
                      </h4>
                      <Controller
                        control={control}
                        name="status"
                        render={({ field }) => (
                          <Select
                            placeholder="Status"
                            register={register}
                            label="status"
                            name="Status"
                            onChange={(e) =>
                              field.onChange(e, handleTaskStatus(e))
                            }
                            selected={field.value}
                            value={field.value}
                            className={css.react_Select}
                            options={StatusOption}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div className={css.selectItem}>
                                <span style={{ color: e.bg }}>{e.icon}</span>
                                {e.label}
                              </div>
                            )}
                          />
                        )}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* -------------------Task Type ------------------- */}
            <div className={css.taskBox}>
              <div className={css.taskBox_Heading}>
                <h2>Task Type :</h2>
              </div>
              <div className={css.task_DetailBoxs}>
                <div className={css.task_DetailBox}>
                  <h4>
                    <ConfirmationNumberOutlinedIcon /> Ticket Type:
                  </h4>
                  <Controller
                    control={control}
                    name="Ticket_type"
                    render={({ field }) => (
                      <Select
                        placeholder="Ticket Type"
                        register={register}
                        label="Ticket_type"
                        name="Ticket_type"
                        onChange={(e) => field.onChange(e)}
                        selected={field.value}
                        value={field.value}
                        className={css.react_Select}
                        options={TicketOption}
                        isSearchable={false}
                        getOptionLabel={(e) => (
                          <div className={css.selectItem}>
                            <span style={{ color: e.bg }}>{e.icon}</span>
                            {e.label}
                          </div>
                        )}
                      />
                    )}
                  />
                </div>
                <div className={css.task_DetailBox}>
                  <h4>
                    <DevicesFoldOutlinedIcon /> Dev Type:
                  </h4>
                  <Controller
                    control={control}
                    name="dev_Type"
                    render={({ field }) => (
                      <Select
                        placeholder="Dev Type"
                        label="dev_Type"
                        name="Dev Type"
                        onChange={(e) => field.onChange(e)}
                        selected={field.value}
                        value={field.value}
                        className={css.react_Select}
                        options={DevTypeOption}
                        isSearchable={false}
                        register={register}
                        getOptionLabel={(e) => (
                          <div className={css.selectItem}>
                            <span style={{ color: e.bg }}>{e.icon}</span>
                            {e.label}
                          </div>
                        )}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            {/* Task Actual Time */}
            {taskActualTime && (
              <div className={css.taskBox}>
                <div className={css.taskBox_Heading}>
                  <h2>Task Actual Time :</h2>
                </div>
                <div className={css.task_DetailBoxs}>
                  <div className={css.task_DetailBox}>
                    <h4>
                      <HistoryToggleOffOutlinedIcon /> Actual Time:
                    </h4>
                    <div style={{ width: "100%" }}>
                      <Input
                        placeholder="Actual Time"
                        label="actual_time"
                        name="Actual Time"
                        type="number"
                        check={{
                          required: "Actual Time is Required",
                        }}
                        errors={errors}
                        register={register}
                      />
                    </div>
                  </div>
                  <div className={css.task_DetailBox}></div>
                </div>
              </div>
            )}

            {/* -------------------Task Button ------------------- */}
            <div className={css.task_Button}>
              <div className={css.task_Button_Wrppaer}>
                <button type="submit">
                  {AddTask ? "Add Task" : "Edit Task"}
                </button>
                <button type="button" onClick={handleCancel}>
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddTask;
