import React, { useEffect, useState } from "react";

// css
import css from "../styles/forgetpassword.module.scss";

// Material Icons
import LockOpenIcon from "@mui/icons-material/LockOpen";

// React Link
import { Link, useNavigate, useParams } from "react-router-dom";
//useform
import { useForm } from "react-hook-form";
// Framer motion
import { motion } from "framer-motion";
//input
import Input from "../components/sharedComponents/input";
// axios
import axios from "axios";

const ResetPassword = () => {
  const { id, token } = useParams();
  const Navigate = useNavigate();
  const [message, setMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    // watch,
    reset,
  } = useForm({
    mode: "onTouched",
  });

  const isUserUserValid = async () => {
    try {
      const res = await axios.get(
        `http://localhost:8080/forgetpass/${id}/${token}`
      );
      const data = res.data;
      if (data.error.verify === false) {
        Navigate("*");
      } else {
        console.log("error valid");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    isUserUserValid();
  }, []);

  // send password for finally reset
  const onSubmit = async (Data) => {
    const newPassword = Data.password;
    const res = await axios.post(`http://localhost:8080/${id}/${token}`, {
      newPassword,
    });
    const data = res.data;
    if (data.status.code === 201) {
      reset();
    }
  };

  return (
    <motion.div
      className={css.forgetpass}
      initial={{ scale: 0 }}
      whileInView={{ scale: 1 }}
      exit={{ scale: 0 }}
      transition={{ duration: 0.4, type: "linear" }}
    >
      <div className={css.forget_left}>
        <h1>Welcome!</h1>
        <h4>Enter your details and start journey with us</h4>
      </div>
      <div className={css.forget_right}>
        <span
          style={{
            color: "green",
            fontSize: "18px",
            textTransform: "capitalize",
          }}
        >
          {message && message}
        </span>
        <div className={css.forget_top}>
          <h1>Password Reset </h1>
        </div>
        <div className={css.forget_bottom}>
          <div className={css.forget_bottom_wrapper}>
            <div className={css.forget_details}>
              <span>
                <LockOpenIcon />
              </span>
              <p>Enter your new password and we will reset your password</p>
            </div>

            <div className={css.forget_main}>
              <div className={css.forget_input}>
                <span>New Password</span>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <Input
                      label="password"
                      name="Password"
                      check={{ required: "Password is Required", minLength: 8 }}
                      register={register}
                      type="password"
                      placeholder="New password *"
                      errors={errors}
                    />
                  </div>

                  <button
                    style={{
                      marginTop: "20px",
                      paddingBottom: "10px",
                      paddingTop: "10px",
                    }}
                  >
                    Reset Password
                  </button>
                </form>
              </div>

              <div className={css.forget_footer}>
                <span>Don't have an account ?</span>
                <button>
                  <Link to="/signup">Create an Account</Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ResetPassword;
