import React, { useState } from "react";

// Css
import css from "../styles/gototop.module.scss";

// material Iconn
import EjectIcon from "@mui/icons-material/Eject";

const Gototop = () => {
  const [upBtn, setUpBtn] = useState(false);

  const changeBtn = () => {
    if (window.scrollY >= 50) {
      setUpBtn(true);
    } else {
      setUpBtn(false);
    }
  };
  const scrollTop = () => {
    window.scrollTo({
      behavior: "smooth",
      top: "0",
    });
  };

  window.addEventListener("scroll", changeBtn);

  return (
    <>
      <div
        className={`${css.gototop} + ${upBtn ? css.gototopActive : ""}`}
        onClick={scrollTop}
      >
        <EjectIcon />
      </div>
    </>
  );
};

export default Gototop;
