import React from "react";
import { useEffect } from "react";
import { useState } from "react";

// Css
import css from "../styles/mousemove.module.scss";

// Framer Motion
import { motion } from "framer-motion";

const MouseMove = () => {
  const [largecircle, setLargeCircle] = useState({ x: 0, y: 0 });
  useEffect(() => {
    const mousemove = (e) => {
      setLargeCircle({ x: e.clientX, y: e.clientY });
    };
    window.addEventListener("mousemove", mousemove);

    return () => {
      window.removeEventListener("mousemove", mousemove);
    };
  }, []);

  return (
    <>
      <div className={css.MouseMove}>
        <motion.div
          animate={{
            x: largecircle.x - -5,
            y: largecircle.y - -25,
            transition: { type: "spring", mass: 1 },
          }}
          className={css.large_circle}
        ></motion.div>
      </div>
    </>
  );
};

export default MouseMove;
