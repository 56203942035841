import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../config/api_url";
import { postData } from '../../config/AxiosFunction'
const loginUser = createAsyncThunk(
  "loginUser",
  async ({ Data }) => {
    if (!Data) {
      return {}
    }
    const response = await postData(`${api}user/session/login`, Data);
    return response
  });
export default loginUser;
