import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../config/api_url";
import { getData, postData, updateData } from "../../config/AxiosFunction";

// Get All Task
export const GetAllTasks = createAsyncThunk("GetAllTasks", async () => {
  const responseData = await getData(`${api}tasks`, "get");
  return responseData;
});

export const GetAllTasksForEmployee = createAsyncThunk("GetAllTasksForEmployee", async ({ id }) => {
  const responseData = await getData(`${api}tasks?userid=${id}`, "get");
  return responseData;
});

// Add Task
export const AddTaskAction = createAsyncThunk("AddTaskAction", async ({ Data, id }) => {
  const responseData = await postData(`${api}tasks?projectid=${id}`, Data);
  return responseData;
});

// Update Task
export const UpdateTaskAction = createAsyncThunk(
  "UpdateTaskAction",
  async ({ Data, id }) => {
    const responseData = await updateData(`${api}tasks?id=${id}`, Data);
    return responseData;
  }
);

// Task Get By Id
export const GetAllTaskByProjectIdAction = createAsyncThunk(
  "GetAllTaskByProjectIdAction",
  async (id) => {
    const responseData = await getData(`${api}tasks?id=${id}`, "get");
    return responseData;
  }
);

export const GetTaskByIdAction = createAsyncThunk(
  "GetTaskByIdAction",
  async (id) => {
    const responseData = await getData(`${api}tasks/getTask?taskid=${id}`, "get");
    return responseData;
  }
);

// Get Project by Employee Id
export const getprojectByEmployeeId = createAsyncThunk(
  "getprojectByEmployeeId",
  async (userId) => {
    const responseData = await getData(`${api}projects?id=${userId}`, "get");
    return responseData;
  }
);


// Count 
export const GetAllTasksCount = createAsyncThunk("GetAllTasksCount", async () => {
  const responseData = await getData(`${api}tasks/count`, "get");
  return responseData;
});
export const GetAllTasksCountForEmployee = createAsyncThunk("GetAllTasksCountForEmployee", async ({ id }) => {
  const responseData = await getData(`${api}tasks/count?id=&userid=${id}`, "get");
  return responseData;
});

export const GetCountById = createAsyncThunk("GetCountById", async ({ id }) => {
  const responseData = await getData(`${api}tasks/count?id=${id}`, "get");
  return responseData;
});