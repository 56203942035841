import React, { useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { CircularProgress, Button } from "@mui/material";
// Css
import css from "../styles/addEmployees.module.scss";
import "../styles/global.scss";

// Components
import Input from "./sharedComponents/input";
import { Alert } from "@mui/material";

// From
import { useForm } from "react-hook-form";
import Select from "./sharedComponents/Select";

// Redux
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { employSelector } from "../redux/slice/EmployeSlice";

import {
  AddEmployeeAction,
  EmployeeGetByIdAction,
  EmployupdateAction,
} from "../redux/actions/Employees";
import AlertComponent from "./Alert";

const AddEmployees = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [buttonClick, setButtonClick] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [response, setResponse] = useState(false);
  const [loader, setLoader] = useState(null);
  const { id } = useParams();
  const isAddMode = !id;

  const [employeeRole, setEmployeeRole] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm({
    mode: "onTouched",
  });
  const Employee = useSelector(employSelector);
  const EmployeeById = Employee.employeeDataById?.data;
  const role = async () => {
    try {
      const response = await axios.get("http://localhost:8080/roles");
      const Role = response.data.data.users;
      setEmployeeRole(Role);
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    role();
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(EmployeeGetByIdAction(id));
    }
  }, []);
  useEffect(() => {
    if (buttonClick) {
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
        setResponse(false);
        setLoader(null);
        Navigate("/employees");
      }, 2000);
    }
  }, [buttonClick]);

  useEffect(() => {
    if (!isAddMode && EmployeeById) {
      const roleId = EmployeeById.roles[0].id;
      const selectedEmployeeData = employeeRole?.find(
        ({ id }) => id === roleId
      );
      const filterData = {
        id: selectedEmployeeData?.id,
        option: selectedEmployeeData?.role_name,
      };
      const fields = ["first_name", "last_name", "email", "phone_no", "status"];
      fields.forEach((field) => setValue(field, EmployeeById[field]));
      setValue("role_id", filterData?.id);
    }
  }, [EmployeeById]);

  const statuSelect = ["Pending", "Approved"];
  const editStatusSelect = ["Pending", "Approved", "Left"];

  const Role = employeeRole?.map((data) => {
    return {
      id: data?.id,
      option: data?.role_name,
    };
  });

  const Password = useRef({});
  Password.current = watch("password", "");

  const onSubmit = (Data) => {
    setButtonClick(true);
    {
      isAddMode
        ? dispatch(AddEmployeeAction({ Data })).then(
          () => {
            setAlertMessage("Employee Add Successfully")
            setResponse(true)
            setLoader(response)
          }
        )
        : dispatch(EmployupdateAction({ Data, id })).then(
          () => {
            setAlertMessage("Employee Updated Successfully")
            setLoader(response)
          }
        );
    }
    reset();
  };

  return (
    <>
      <div className={css.employees}>
        <div className={css.employees_wrapper}>
          <div className={css.employees_top}>
            <div className={css.employees_left}>
              <h1>{isAddMode ? "Add Employee" : "Edit Employee"}</h1>
              <h5>
                <Link to="/employees">Employees</Link> /{" "}
                {isAddMode ? "Add Employee" : "Edit Employee"}
              </h5>
            </div>
            <div className={css.employees_right}></div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={css.add_employees}>
              {/* Frist Name & Last Name*/}

              <div className={css.add_employees_inputs}>
                <div className={css.employees_input_wrapper}>
                  <div>
                    <label>
                      First Name <span>*</span>
                    </label>
                    <Input
                      label="first_name"
                      register={register}
                      name="First Name"
                      check={{
                        required: "First name is Required",
                        pattern: /^[A-Za-z]+$/i,
                        minLength: 3,
                      }}
                      type="text"
                      placeholder="First Name *"
                      errors={errors}
                    />
                  </div>
                  <div>
                    <label>
                      Last Name <span>*</span>
                    </label>
                    <Input
                      label="last_name"
                      register={register}
                      name="Last Name"
                      check={{
                        required: "Last Name is Required",
                        pattern: /^[A-Za-z]+$/i,
                        minLength: 3,
                      }}
                      type="text"
                      placeholder="Last Name *"
                      errors={errors}
                    />
                  </div>
                </div>
              </div>

              {/* Email*/}

              {isAddMode && (
                <div className={css.add_employees_inputs}>
                  <div
                    className={`${css.employees_input_wrapper} ${css.employeesInput}`}
                  >
                    <div>
                      <label>
                        Email <span>*</span>
                      </label>
                      <Input
                        label="email"
                        register={register}
                        name="Email"
                        check={{
                          required: "Email is Required",
                          pattern: "/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/",
                        }}
                        type="text"
                        placeholder="Your Email *"
                        errors={errors}
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* Password & Confirm Password*/}
              {isAddMode && (
                <div className={css.add_employees_inputs}>
                  <div className={css.employees_input_wrapper}>
                    <div>
                      <label>
                        Password <span>*</span>
                      </label>
                      <Input
                        label="password"
                        name="Password"
                        register={register}
                        check={{
                          required: "Password is Required",
                          minLength: 8,
                        }}
                        type="password"
                        placeholder="password *"
                        errors={errors}
                      />
                    </div>
                    <div>
                      <label>
                        ConfirmPassword <span>*</span>
                      </label>
                      <Input
                        label="ConfirmPassword"
                        register={register}
                        name="Confirm Password"
                        check={{
                          required: "Confirm Password is Required",
                          validate: (value) =>
                            value === Password.current ||
                            "The passwords do not match",
                        }}
                        type="password"
                        placeholder="confirm password *"
                        errors={errors}
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* Phone Number */}

              <div className={css.add_employees_inputs}>
                <div
                  className={`${css.employees_input_wrapper} + ${css.employeesInput}`}
                >
                  <div>
                    <label>
                      Phone Number <span>*</span>
                    </label>
                    <Input
                      label="phone_no"
                      register={register}
                      name="Phone No"
                      check={{
                        required: "Phone No is Required",
                        minLength: 11,
                      }}
                      type="number"
                      placeholder="Mobile Number *"
                      errors={errors}
                    />
                  </div>
                </div>
              </div>

              {/* Role Select & Status Select*/}

              <div className={css.add_employees_inputs}>
                <div className={css.employees_input_wrapper}>
                  <div>
                    <label>
                      Select Role <span>*</span>
                    </label>
                    <Select
                      option={Role}
                      label={`role_id`}
                      name="Role id"
                      placeholder="Role/Designation"
                      register={register}
                      errors={errors}
                      check={{
                        required: "Role is Requried",
                      }}
                    />
                  </div>
                  <div>
                    <label>
                      Select Status <span>*</span>
                    </label>
                    <Select
                      option={isAddMode ? statuSelect : editStatusSelect}
                      placeholder="Status"
                      register={register}
                      errors={errors}
                      label="status"
                      name="Status"
                      check={{
                        required: "Status is Requried",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className={css.add_employees_btn}>
                {isAddMode ? (
                  <Button type="submit" disabled={loader !== null}>
                    {loader === false || loader ? (
                      <CircularProgress
                        color="inherit"
                        className={css.loader}
                      />
                    ) : (
                      "Add Employee"
                    )}
                  </Button>
                ) : (
                  <Button type="submit" disabled={loader !== null}>
                    {loader === false || loader ? (
                      <CircularProgress
                        color="inherit"
                        className={css.loader}
                      />
                    ) : (
                      "Edit Employee"
                    )}
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      {showAlert && (
        <AlertComponent
          response={response}
          severity={"success"}
          message={alertMessage}
          classs={"loginAlertSuccess"}
        />
      )}
    </>
  );
};

export default AddEmployees;
