import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Css
import css from "../styles/employeesDataBox.module.scss";

// Material Icon
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { GetEmployeeData } from "../redux/actions/Employees";
import { UserShortName } from "../Utils";

const EmpolyeesDataBox = () => {
  const [search, setSearch] = useState("");
  const [EmployeeData, setEmployeeData] = useState()
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const employData = useSelector((state) => state.employee?.gettAllData);
  const Data = employData?.data?.users;

  useEffect(() => {
    dispatch(GetEmployeeData());
  }, []);

  useEffect(() => {
    const result = Data?.filter((TableData) => {
      const UserName = `${TableData.first_name} ${TableData.last_name}`;
      return (
        UserName.toLowerCase()
          .trim()
          .replace(/\s/g, "")
          .match(search?.toLowerCase().trim().replace(/\s/g, "")) ||
        TableData.email?.toLowerCase().match(search.toLowerCase().trim()) ||
        TableData.phone_no?.toLowerCase().match(search.toLowerCase().trim()) ||
        TableData.status?.toLowerCase().match(search.toLowerCase().trim()) ||
        TableData.join_date?.toLowerCase().match(search.toLowerCase().trim())
      );
    });
    setEmployeeData(result)
  }, [search]);

  // Edit Employee
  const handleEditEmploy = (e) => {
    navigate(`edit/${e.id}`);
  };

  // Employee Description
  const handleDescription = (e) => {
    navigate(`description/${e.id}`);
  };

  // Employee Delete
  const [employDelete, setemployDelete] = useState(false);
  const handleDelete = () => {
    setemployDelete(true);
  };
  return (
    <>
      <div className={css.employeesBoxs}>
        <div className={css.employeesBoxSearch}>
          <input
            type="text"
            placeholder="Search Here"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className={css.employeesBoxs_wrapper}>
          {EmployeeData?.map((items, i) => {
            const userDate =
              new Date(items.createdAt).getDate() +
              "-" +
              new Date(items.createdAt).toLocaleString("en-us", {
                month: "short",
              }) +
              "-" +
              new Date(items.createdAt).getFullYear();
            return (
              <div className={css.employeesBox} key={i}>
                <div className={css.employeesboxMenu}>
                  <div
                    className={css.employeesboxSubMenu}
                    onClick={(e) => handleDescription(items)}
                  >
                    <VisibilityIcon />
                  </div>
                  {/* <div
                    className={css.employeesboxSubMenu}
                    onClick={handleDelete}
                  >
                    <DeleteIcon />
                  </div> */}
                  <div
                    className={css.employeesboxSubMenu}
                    onClick={(e) => handleEditEmploy(items)}
                  >
                    <EditIcon />
                  </div>
                </div>
                <div className={css.employeesBox_img}>
                  <h1><UserShortName firstName={items?.first_name} lastName={items?.last_name}/></h1>
                </div>
                <div className={css.employeesBox_detail}>
                  <h3>{`${items.first_name} ${items.last_name}`}</h3>
                  <h4>Mern Stack Developer</h4>
                  <h2>{userDate}</h2>
                  <h6>{items.email}</h6>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <DeleteEmployee
        setemployDelete={setemployDelete}
        employDelete={employDelete}
      /> */}
    </>
  );
};

export default EmpolyeesDataBox;
