import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../config/api_url";
import { getData, updateData } from "../../config/AxiosFunction";

export const ProfileAction = createAsyncThunk(
  "profile/profileAction",
  async (id) => {
    const responseData = await getData(`${api}users/` + id, "get");
    return responseData;
  }
);

export const ProfileUpdateAction = createAsyncThunk(
  "ProfileUpdateAction",
  async ({ id, Data }) => {
    const response = await updateData(`${api}users/${id}`, Data);
    return response;
  }
);

