import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import Background from "../components/Background";
const PublicRoutes = () => {
  const location = useLocation();

  const auth = JSON.parse(localStorage.getItem("token"));

  return !auth?.accessToken ? (
    <Background>
      <Outlet />
    </Background>
  ) : auth?.role === "employee" ? (
    <Navigate to="/dashboard" state={{ from: location }} replace />
  ) : (
    <Navigate to="/dashboard" state={{ from: location }} replace />
  );
};

export default PublicRoutes;
