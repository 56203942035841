import React from "react";
import Alert from "@mui/material/Alert";
import "../styles/global.scss";
const AlertComponent = ({ message, response, classs, severity }) => {
    return (
        <div>
            <Alert
                severity={response ? severity : severity}
                className={response ? classs : classs}
            >
                {message}
            </Alert>
        </div>
    );
};

export default AlertComponent;
