import { configureStore } from "@reduxjs/toolkit"
import { userSlice } from "../slice/UserSlice"
import { ProjectSlice } from "../slice/ProjectSlice";
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { combineReducers } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import { EmployeeSlice } from "../slice/EmployeSlice";
import { TaskSlice } from "../slice/TaskSlice";
import { FilterSlice } from "../slice/FilterSlice";
// import { dashboard } from "../slice/DashboardSlice"
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ["valueState"]
}

const rootReducer = combineReducers({
  // valueState: ProfileSlice.reducer,
  valueState: userSlice.reducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = configureStore({
  reducer: {
    user: persistedReducer,
    task: TaskSlice.reducer,
    ProjectCreate: ProjectSlice.reducer,
    employee: EmployeeSlice.reducer,
    getFilterData: FilterSlice.reducer,
    middleware: [thunk]
  },
})


export default store