import React from "react";
import Styles from "../styles/select.module.scss";
const SelectInput = (props) => {
  const { option, readonly, value, onChange, register, label, check } =
    props;

  return (
    <>
      <div className="select">
        <select
          {...(register ? register(label, check) : {})}
          className={Styles.mySelectArrow}
          readonly={readonly}
          value={value}
          onChange={onChange}
        >
          {/* <optgroup> */}
          <option value="" disabled selected hidden>
            Select Option
          </option>
          {option?.map((city) => (
            <option value={city}>{city}</option>
          ))}
        </select>
      </div>
    </>
  );
};

export default SelectInput;
