import { createSlice } from "@reduxjs/toolkit";
import { FilterEmployees, FilterProjects, FilterTasks } from "../actions/FilterAction";

export const FilterSlice = createSlice({
    name: "GetFilterData",
    initialState: {
        message: [],
        FilterProject: {},
        FilterEmployee: {},
        FilterTask: {},
    },
    reducers: {
        // Reducer comes here
    },
    extraReducers: {
        // Filter Projects
        [FilterProjects.fulfilled]: (state, { payload }) => {
            state.FilterProject = payload;
            return state;
        },
        [FilterProjects.pending]: (state, { }) => {
            state.isFetching = true;
        },
        [FilterProjects.rejected]: (state, { payload }) => {
            state.errorMessage = payload.FilterProject;
        },
        //Filter Employees
        [FilterEmployees.fulfilled]: (state, { payload }) => {
            state.FilterEmployee = payload;
            return state;
        },
        [FilterEmployees.pending]: (state, { }) => {
            state.isFetching = true;
        },
        [FilterEmployees.rejected]: (state, { payload }) => {
            state.errorMessage = payload;
        },

        //Filter Tasks
        [FilterTasks.fulfilled]: (state, { payload }) => {
            state.FilterTask = payload;
            return state;
        },
        [FilterTasks.pending]: (state, { }) => {
            state.isFetching = true;
        },
        [FilterTasks.rejected]: (state, { payload }) => {
            state.errorMessage = payload;
        },
    },
});
export const getFilterData = (state) => state.GetFilterData;
