import React from "react";
import { useState } from "react";
import { useEffect } from "react";

// Csss
import css from "../styles/sidebar.module.scss";

// React Links
import { NavLink, useLocation, useNavigate } from "react-router-dom";

// Pages
import Navbar from "./navbar";

// Images
import TaskLogo from "../assets/images/tasklogo.png";

// Icons
import FolderIcon from "@mui/icons-material/Folder";
import SpeedIcon from "@mui/icons-material/Speed";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import CloseIcon from "@mui/icons-material/Close";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AddIcon from "@mui/icons-material/Add";

// Components
import Gototop from "./Gototop";
import Customizer from "./Customizer";

// Redux
import { userSelector } from "../redux/slice/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import loginUser from "../redux/actions/login";
import { ProjectCreate } from "../redux/slice/ProjectSlice";
import { GetAllProjects } from "../redux/actions/GetProjectsAction";
import { taskSelector } from "../redux/slice/TaskSlice";
import { getprojectByEmployeeId } from "../redux/actions/TaskAction";

const NewSidebar = ({ children }) => {
  const adminRoute = [
    {
      link: "/dashboard",
      label: "Dashboard",
      Icon: <SpeedIcon />,
    },
    {
      link: "/projects",
      label: "Projects",
      Icon: <FolderIcon />,
    },
    {
      link: "/task?project=all",
      label: "Tasks",
      Icon: <AssignmentIndIcon />,
    },
    {
      link: "/attendance",
      label: "Attendance",
      Icon: <CalendarMonthIcon />,
    },
    {
      link: "/employees",
      label: "Employees",
      Icon: <Groups2OutlinedIcon />,
    },
  ];

  const employeeRoute = [
    {
      link: "/dashboard",
      label: "Dashboard",
      Icon: <SpeedIcon />,
    },

    {
      link: "/task?project=all",
      label: "Tasks",
      Icon: <AssignmentIndIcon />,
    },
    {
      link: "/attendance",
      label: "Attendance",
      Icon: <CalendarMonthIcon />,
    },
  ];
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [toggle, settoggle] = useState(false);
  const [horizontal, sethorizontal] = useState(false);
  const [horizontalbutton, sethorizontalbutton] = useState(false);
  const [hidden, sethidden] = useState(false);
  const [hiddenbutton, sethiddenButton] = useState(false);
  const [taskMenu, setTaskMenu] = useState(false);
  const [taskActive, setTaskActive] = useState("");
  const [taskResponsive, setTaskResponsive] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [isSubMenu, setIsSubMenu] = useState(false);

  const { userData } = useSelector(userSelector);
  const data = userData.data?.user;
  const userId = userData.data?.user.id;
  const role = data.roles?.role_name;
  const Routes = role === "admin" ? adminRoute : employeeRoute;
  const pathname = window.location.pathname;
  const pathName = location.pathname.split("/").slice(1, -1).join("/");
  const params = new URLSearchParams(location.search);
  const projectId = params.get("project");

  const AddTask = pathName === "task/add";
  const EditTask = pathName === "task/edit";
  const TaskDescription = pathName === "task/description";

  const { projectData } = useSelector(ProjectCreate);
  const { getProjectForEmployee } = useSelector(taskSelector);
  const Allproject = projectData.data?.project;
  const projectByUserId = getProjectForEmployee.data?.project;
  const projects = role === "employee" ? projectByUserId : Allproject;

  useEffect(() => {
    if (role === "employee") {
      dispatch(getprojectByEmployeeId(userId));
    } else {
      dispatch(GetAllProjects());
    }
    dispatch(loginUser());
  }, []);

  useEffect(() => {
    if (horizontal && projects?.length > 3) {
      setTaskResponsive(true);
    } else {
      setTaskResponsive(false);
    }
  }, [horizontal]);

  useEffect(() => {
    if (pathname === "/task" || AddTask || EditTask || TaskDescription) {
      setTaskMenu(true);
      settoggle(false);
    } else setTaskMenu(false);
  }, [pathname]);

  useEffect(() => {
    if (projectId) {
      setTaskActive(projectId);
    }
  }, [projectId]);

  const handleChange = () => {
    setTaskMenu(false);
    Navigate("/dashboard");
  };

  const handleClick = (items) => {
    Navigate(`/task?project=${items.id}`);
    if (window.innerWidth <= 900) {
      settoggle(false);
      sethiddenButton(false);
    }
  };

  const handleAllTask = () => {
    Navigate(`/task?project=all`);
  };
  const handleClose = () => {
    setIsNotification(false);
    setIsSubMenu(false);
  };
  const handleSidebarChange = () => {
    if (window.innerWidth <= 900) {
      settoggle(false);
      sethiddenButton(false);
    }
    sethiddenButton(false)
    sethorizontalbutton(false);
  };

  return (
    <>
      <div className={css.main_container}>
        <div
          className={`${css.sidebar} 
          ${toggle ? css.sidebarActive : ""} 
          ${horizontal ? css.sidebarhorizontal : ""}
          ${hidden ? css.sidebarhidden : ""}
          ${hiddenbutton ? css.sidebarhiddenbtn : ""}
          `}
          onClick={handleClose}
        >
          <div
            className={`${css.sidebar_wrapper} ${horizontalbutton ? css.sidebar_wrapperActive : ""
              }`}
          >
            {/* ----------------------Task  Start------------------- */}
            <div
              className={`${css.sidebar_task} ${taskMenu ? css.sidebar_taskActive : ""
                }`}
            >
              <div className={css.sidebar_task_wrapper}>
                <div
                  className={`${css.sidebar_Task_Top}
                  `}
                  onClick={handleChange}
                >
                  {/* ${taskResponsive ? css.sidebar_Task_TopActive : ""}  */}
                  <span>
                    <HomeOutlinedIcon /> Back to Home
                  </span>
                </div>

                <div
                  className={`${css.sidebar_Task_menu}
                  `}
                >
                  {/* ${taskResponsive ? css.sidebar_Task_menuActive : ""} */}
                  <ul>
                    {role === "admin" && (
                      <NavLink to="/projects/add">
                        <li>
                          Add Project <AddIcon />
                        </li>
                      </NavLink>
                    )}
                    <li
                      className={`${taskActive === "all" ? css.Activehe : ""}`}
                      onClick={handleAllTask}
                    >
                      {role === "employee" ? "My Task" : "All Task"}
                    </li>
                    {projects?.map((items, i) => {
                      const index = i + 1;
                      return (
                        <>
                          <li
                            key={index}
                            className={`${
                              +taskActive === items.id && taskActive != "all"
                                ? css.Activehe
                                : ""
                            }`}
                            onClick={() => handleClick(items)}
                          >
                            {items.project_name}
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
              {/* <div
                className={`${css.handburgerMenuTask} 
              ${taskResponsive ? css.handburgerMenuTaskActive : ""}
              `}
              >
                <div
                  className={`handburgerMenu`}
                  onClick={() => setTaskResponsive(!taskResponsive)}
                >
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                </div>
              </div> */}
            </div>
            {/* ----------------------Task End------------------- */}

            <div className={css.sidebartop}>
              <div className={css.closelogo}>
                <CloseIcon onClick={() => settoggle(false)} />
              </div>
              <div
                className={`handburgerMenu ${css.horizontalclose} ${horizontalbutton ? "handburgerActive" : ""
                  }`}
                onClick={() => sethorizontalbutton(!horizontalbutton)}
              >
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </div>
              {hidden && (
                <div
                  className={css.hiddenClose}
                  onClick={() => sethiddenButton(false)}
                >
                  <CloseIcon />
                </div>
              )}
              <div className={css.sidebarLogo}>
                <img src={TaskLogo} alt="" />
              </div>
              <span>{role}</span>
            </div>
            <div className={css.sidebarbottom}>
              {Routes.map((items, i) => {
                return (
                  <>
                    <ul className={css.sidebarmenu}>
                      <NavLink
                        key={i}
                        to={items.link}
                        className={({ isActive }) =>
                          isActive ? css.navbarActive : ""
                        }
                        onClick={handleSidebarChange}
                      >
                        <li>
                          <span className={css.sidebarIcon}>{items.Icon}</span>
                          <span className={css.sidebarText}>{items.label}</span>
                        </li>
                      </NavLink>
                    </ul>
                  </>
                );
              })}
            </div>
          </div>
        </div>
        <Navbar
          toggle={toggle}
          settoggle={settoggle}
          horizontal={horizontal}
          sethidden={sethidden}
          hidden={hidden}
          hiddenbutton={hiddenbutton}
          sethiddenButton={sethiddenButton}
          taskMenu={taskMenu}
          isNotification={isNotification}
          setIsNotification={setIsNotification}
          isSubMenu={isSubMenu}
          setIsSubMenu={setIsSubMenu}
        />
        <div
          className={`${css.main} 
          ${toggle ? css.mainActive : ""} 
          ${horizontal ? css.mainhorizontal : ""}
          ${hidden ? css.mainhidden : ""}
          `}
          onClick={handleClose}
        >
          <Customizer
            toggle={toggle}
            settoggle={settoggle}
            horizontal={horizontal}
            sethorizontal={sethorizontal}
            hidden={hidden}
            sethidden={sethidden}
          />
          {children}
          <Gototop />
        </div>
      </div>
    </>
  );
};

export default NewSidebar;
