import React, { useState } from "react";

// Css
import Styles from "../styles/profile.module.scss";

// Material Styles
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

// Material Icon
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";

const AddSkill = ({
  modal,
  setModal,
  skillData,
  setSkillData,
  setChipData,
  chipData,
}) => {
  const handleCloseModal = () => {
    setModal(false);
    setSkillData([{ skill: "" }]);
  };

  const handleSkilldelete = (index) => {
    const list = [...skillData];
    list.splice(index, 1);
    setSkillData(list);
  };

  const handleSkillValue = (e, index) => {
    const { name, value } = e.target;
    const list = [...skillData];
    list[index][name] = value;
    setSkillData(list);
  };

  const handleAddSkill = () => {
    setSkillData([...skillData, { skill: "" }]);
  };

  const handleSaveSkill = () => {
    const list = [...skillData];
    const Data = list.filter(({ skill }) => skill !== "");
    setChipData([...chipData, ...Data]);
    setSkillData([{ skill: "" }]);

    setModal(false);
  };

  return (
    <>
      <Dialog open={modal}>
        <DialogTitle
          sx={{
            textAlign: "center",
            width: "400px",
            color: "#283686",
            fontSize: "1.2rem",
            letterSpacing: "0.5px",
          }}
          className={Styles.skillboxText}
        >
          ADD SKILLS
          <div className={Styles.skillBoxClose} onClick={handleCloseModal}>
            <CloseIcon />
          </div>
        </DialogTitle>
        <DialogContent sx={{ padding: "5px 24px" }} className={Styles.skillDialogBox}>
          {skillData.map((data, index) => {
            return (
              <>
                <div className={`${Styles.skillInputs}`} key={index}>
                  <input
                    type="text"
                    placeholder="Add Skill"
                    name="skill"
                    className={Styles.mainInput}
                    value={data.skill}
                    onChange={(e) => handleSkillValue(e, index)}
                  />
                  {skillData.length > 1 && (
                    <CancelIcon onClick={() => handleSkilldelete(index)} />
                  )}
                </div>
              </>
            );
          })}
        </DialogContent>

        <DialogActions className={`${Styles.skillbtn} `}>
          <button onClick={handleAddSkill}>
            Add Skill <AddIcon />
          </button>
          <button onClick={handleSaveSkill}>
            Save Skill <LibraryAddCheckOutlinedIcon />
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddSkill;
