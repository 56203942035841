import React, { useState } from "react";
import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// Css
import css from "../styles/navbar.module.scss";

// Material
import { Box } from "@mui/material";

// Icons
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchIcon from "@mui/icons-material/Search";
import TextsmsRoundedIcon from "@mui/icons-material/TextsmsRounded";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

// Components
import { UserShortName } from "../Utils";
import NotificationMenu from "./NotificationMenu";

// Redux
import loginUser from "../redux/actions/login";
import { logout, userSelector } from "../redux/slice/UserSlice";
import { useDispatch, useSelector } from "react-redux";

const Navbar = ({
  toggle,
  settoggle,
  horizontal,
  hidden,
  hiddenbutton,
  sethiddenButton,
  taskMenu,
  isNotification,
  setIsNotification,
  isSubMenu,
  setIsSubMenu,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector(userSelector);
  const data = userData.data?.user;
  useEffect(() => {
    dispatch(loginUser());
  }, []);

  // Sticky Navbar Start
  const [stickyNavbar, setStickyNavbar] = useState(false);
  const ChangeNav = () => {
    if (window.scrollY >= 250) {
      setStickyNavbar(true);
    } else {
      setStickyNavbar(false);
    }
  };
  window.addEventListener("scroll", ChangeNav);
  // Sticky Navbar End

  // Sub Menu Start
  const handleClick = () => {
    setIsSubMenu(!isSubMenu);
    setIsNotification(false);
  };

  const handleNotification = () => {
    setIsNotification(!isNotification);
    setIsSubMenu(false);
  };

  const handleClose = () => {
    setIsSubMenu(false);
  };

  const handleMenuClose = (e) => {
    if (e) {
      localStorage.removeItem("token");
      localStorage.removeItem("profileData");
      localStorage.removeItem("roles");
      localStorage.removeItem("Id");
      dispatch(logout());
      navigate("/login");
    }
  };
  // Sub Menu End

  const Toggle = () => {
    settoggle(!toggle);
  };

  return (
    <div
      className={`${css.navbar}
      ${stickyNavbar ? css.navbarSticky : ""} 
      ${toggle ? css.navbarActive : ""} 
      ${horizontal ? css.navbarhorizontal : ""} 
    ${hidden ? css.navbarhidden : ""}
    `}
    >
      <div className={css.navbar_wrapper}>
        <Box className={css.NavbarLeft}>
          <div
            className={`${css.handburgerMain}   ${
              taskMenu ? css.handburgerHidden : ""
            }`}
          >
            <Box
              className={`handburgerMenu ${css.handburgerMenu} ${
                toggle ? "handburgerActive" : ""
              } 
          
            `}
              onClick={Toggle}
            >
              <Box className="bar"></Box>
              <Box className="bar"></Box>
              <Box className="bar"></Box>
            </Box>
          </div>

          {hidden && (
            <div
              className={css.hiddenIcon}
              onClick={() => sethiddenButton(!hiddenbutton)}
            >
              <MenuOpenIcon />
            </div>
          )}
          <h3>
            Hey! {data?.first_name} {data?.last_name}
          </h3>
        </Box>
        <Box className={css.NavbarRight}>
          <Box
            className={`${css.NavbarRight_items} ${
              isNotification ? css.notificationIconActive : ""
            }`}
          >
            <NotificationsIcon
              className={css.icon}
              onClick={handleNotification}
            />
            <Box className={css.Counter}>1</Box>
          </Box>
          <NotificationMenu
            isNotification={isNotification}
            setIsNotification={setIsNotification}
          />
          <Box className={css.NavbarRight_items}>
            <TextsmsRoundedIcon className={css.icon} />
            <Box className={css.Counter}>20</Box>
          </Box>

          <Box className={css.NavbarRight_items}>
            <h5 onClick={handleClick}>
              <UserShortName
                firstName={data?.first_name}
                lastName={data?.last_name}
              />
            </h5>
            <div
              className={`${css.SubMenu} ${isSubMenu ? css.SubMenuActive : ""}`}
            >
              <ul>
                <Link to="/profile" onClick={handleClose}>
                  <li>Profile</li>
                </Link>
                <Link onClick={handleMenuClose}>
                  <li>Logout</li>
                </Link>
              </ul>
            </div>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default Navbar;
