import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../config/api_url";
import { getData } from "../../config/AxiosFunction";
export const GetAllProjects = createAsyncThunk(
  "projects/getallproject",
  async () => {
    const responseData = await getData(`${api}projects`, "get");

    return responseData;
  }
);
export const GetProject = createAsyncThunk(
  "projects/getproject",
  async ({id}) => {
    const responseData = await getData(`${api}projects/${id}`, "get");
    return responseData;
  }
);
