import React from "react";
import { useState } from "react";
import { useEffect } from "react";
// Css
import css from "../styles/notification.module.scss";
// Material Icon
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// Components
import { UserShortName } from "../Utils";

// Socket
import { io } from "socket.io-client";

const Notification = () => {
  // Socket Io Connection
  const [socket, setSocket] = useState(null);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    setSocket(io("http://localhost:8080/"));
  }, []);
  useEffect(() => {
    socket?.on("getNotification", (data) => {
      setNotifications((prev) => [...prev, data]);
    });
  }, [socket]);
  return (
    <>
      <div className={css.notification_Main}>
        <div className={`${css.notification} ${css.notificationMain}`}>
          <div className={css.notification_wrapper}>
            <div className={css.notification_heading}>
              <h1>Notification</h1>
              <span>
                <MoreHorizIcon />
              </span>
            </div>
            <div className={css.notification_action}>
              <span>All</span>
              <span>Unread</span>
            </div>
            <div className={css.notification_subHeading}>
              <h3>New</h3>
            </div>
            <div className={css.notification_menu}>
              <ul>
                {notifications.map((item, i) => {
                  return (
                    <li>
                      <div className={css.notification_image}>
                        <div>
                          <span>
                            <UserShortName
                              firstName={item?.firstName}
                              lastName={item?.lastName}
                            />
                          </span>
                        </div>
                      </div>
                      <div className={css.notification_detail}>
                        <p>
                        <span>{`${item.firstName} ${item.lastName}`}</span>
                        Send a Registration Request and His
                        <span>Email: {item.email}</span>and
                        <span>Phone Number: {item.phone}</span>
                        </p>
                      </div>
                      <div className={css.notification_done}>
                        <span>
                          <FiberManualRecordIcon />
                        </span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
