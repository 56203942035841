import { createAsyncThunk } from "@reduxjs/toolkit";
import { postData, getData, updateData } from "../../config/AxiosFunction";
import api from "../../config/api_url";

// Add Employee
export const AddEmployeeAction = createAsyncThunk(
  "AddEmployeeAction",
  async ({ Data }) => {
    const responseData = await postData(`${api}users`, Data);
    return responseData;
  }
);

// Get All Employee
export const GetEmployeeData = createAsyncThunk("GetEmployData", async () => {
  const responseData = await getData(`${api}users`, "get");
  return responseData;
});

export const EmployeeGetByIdAction = createAsyncThunk(
  "employeeGetByIdAction",
  async (id) => {
    const responseData = await getData(`${api}users/` + id, "get");
    return responseData;
  }
);

// Get Update By Id
export const EmployupdateAction = createAsyncThunk(
  "EmployupdateAction",
  async ({ Data, id }) => {
    const response = await updateData(`${api}users/${id}`, Data);
    return response;
  }
);

