import { createSlice } from "@reduxjs/toolkit";
import AddProject from "../actions/AddProjectAction";
import { GetAllProjects } from "../actions/GetProjectsAction";
import { GetProject } from "../actions/GetProjectsAction";
import ProjectUpdateAction from "../actions/ProjectUpdateAction";

export const ProjectSlice = createSlice({
    name: "ProjectCreate",
    initialState: {
        message: [],
        projectData: {},
        projectDataById: {},
        // allProject: {}
    },
    reducers: {
        // Reducer comes here
    },
    extraReducers: {
        // Create PRoject
        [AddProject.fulfilled]: (state, { payload }) => {
            state.message = payload;
            return state;
        },
        [AddProject.pending]: (state, { payload }) => {
            state.isFetching = true;
        },
        [AddProject.rejected]: (state, { payload }) => {
            state.errorMessage = payload;
        },
        //  Get  Project
        [GetProject.fulfilled]: (state, { payload }) => {
            state.projectDataById = payload;
            return state;
        },
        [GetProject.pending]: (state, { payload }) => {
            state.isFetching = true;
        },
        [GetProject.rejected]: (state, { payload }) => {
            state.errorMessage = payload;
        },
        //  Get All Project
        [GetAllProjects.fulfilled]: (state, { payload }) => {
            state.projectData = payload;

            return state;
        },
        [GetAllProjects.pending]: (state, { payload }) => { },
        [GetAllProjects.rejected]: (state, { payload }) => {
            state.errorMessage = payload;
        },
        //  Update Project
        [ProjectUpdateAction.fulfilled]: (state, { payload }) => {
            state.projectData = payload;

            return state;
        },
        [ProjectUpdateAction.pending]: (state, { payload }) => {
            state.isFetching = true;
        },
        [ProjectUpdateAction.rejected]: (state, { payload }) => {
            state.errorMessage = payload;
        },
    },
});
export const ProjectCreate = (state) => state.ProjectCreate;
