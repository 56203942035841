import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../config/api_url";
import { postData } from '../../config/AxiosFunction'

const SignupUser = createAsyncThunk(
  "SignupUser",
  async ({ Data }) => {
    const responseData = await postData(`${api}users`, Data)
    return responseData;
  });

export default SignupUser;
