import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useEffect } from "react";

// Css
import css from "../../styles/taskTable.module.scss";

// Material Icon
import EditIcon from "@mui/icons-material/Edit";
import WidgetsIcon from "@mui/icons-material/Widgets";
import VisibilityIcon from "@mui/icons-material/Visibility";

// Table
import DataTable from "react-data-table-component";
import { UserShortName } from "../../Utils";

// Redux
import { taskSelector } from "../../redux/slice/TaskSlice";
import {
  GetAllTaskByProjectIdAction,
  GetAllTasks,
  GetAllTasksForEmployee,
} from "../../redux/actions/TaskAction";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../redux/slice/UserSlice";
import loginUser from "../../redux/actions/login";
import Filtering from "../Filtering";
import { FilterTasks } from "../../redux/actions/FilterAction"
const TaskTable = ({ id }) => {
  const navigate = useNavigate();


  // Redux
  const dispatch = useDispatch();
  const { userData } = useSelector(userSelector);
  // const { FilterTask } = useSelector(
  //   (state) => state?.getFilterData
  // );
  const searchFilterData = useSelector(
    (state) => state.getFilterData?.FilterTask?.data);
  // Filter STates
  const [filterData, setFilterData] = useState([]);
  const [tableData, setTableData] = useState(false);

  // fetch Data from filtering component
  const statuses = [

    ["All", "Todo", "Inprogress", "Block", "Intesting", "Done"],
    ["All", "High", "Normal", "Low"],
    ["All", "Frontend", "Backend"]
  ];
  const placeholders = ["Select Status", "Select Priority", "Select Dev Type"]
  const [status, setStatus] = useState({
    statusType: "",
    priority: "",
    devType: ""
  });
  const [start_date, setStarting_Date] = useState("");
  const [end_date, setEnding_Date] = useState("");
  const [searchData, setSearchData] = useState("");



  // Role
  const role = userData.data?.user.roles.role_name;
  const userId = userData.data?.user.id;

  useEffect(() => {
    let userID = "";
    let projectID ="";
          if(id === "all"){
        if (role === "employee") {
          userID= userId;
        }
        else{
          userID="";
      }
       }
       else{
        projectID=id;
      }
    dispatch(
      FilterTasks({
        searchData,
        priority: status.priority === "All" ? "" : status.priority,
        status: status.statusType === "All" ? "" : status.statusType,
        devType: status.devType === "All" ? "" : status.devType,
        start_date: start_date ? new Date(start_date) : "",
        end_date: end_date ? new Date(end_date) : "",
        id:projectID,
        userId:userID
      })

    );
    dispatch(loginUser());
  }, [status, searchData, start_date, end_date, id,userId]);

  // Edit Task
  const handleEditTask = (e) => {
    navigate(`edit/${e.id}`);
  };
  // Task Description
  const handleDescription = (e) => {
    navigate(`description/${e.id}`);
  };

  const colums = [
    {
      cell: () => <WidgetsIcon style={{ fill: "#283686" }} />,
      sortable: false,
      width: "56px",
      style: {
        borderBottom: "1px solid #FFFFFF",
      },
    },
    {
      name: "TICKET ID",
      selector: (row) => "#TC-00" + row.id,
      sortable: true,
      width: "150px",
      style: {
        color: "orange",
        fontWeight: 600,
      },
    },
    {
      name: "SUBJECT",
      selector: (row) => row.task_title,
      sortable: true,
      reorder: true,
      width: "150px",
    },
    {
      name: "ASSIGNED",
      selector: (row) => (
        <>
          {row.users.length > 0 ? (
            <div className={css.tableassign}>
              <div className={css.assignIcon}>
                <UserShortName
                  firstName={row.users[0]?.first_name}
                  lastName={row.users[0]?.last_name}
                />
              </div>
              {`${row.users[0]?.first_name} ${row.users[0]?.last_name}`}
            </div>
          ) : (
            "Not assigned"
          )}
        </>
      ),
      sortable: true,
      reorder: true,
      width: "200px",
      style: {
        color: "#202124",
        fontSize: "15px",
        fontWeight: 600,
      },
    },
    {
      name: "CREATE DATE",
      selector: (row) =>
        new Date(row.createdAt).getDate() +
        "-" +
        new Date(row.createdAt).toLocaleString("en-us", { month: "short" }) +
        "-" +
        new Date(row.createdAt).getFullYear(),
      center: true,
      reorder: true,
      width: "180px",
    },
    {
      name: "Estimated Time",
      selector: (row) => row.estimated_time + " " + row.unit,
      reorder: true,
      center: true,
      width: "180px",
    },
    {
      name: "STATUS ",
      cell: (row) => {
        if (row.status === "Block") {
          return <span className={css.statusBlock}>{row.status}</span>;
        } else if (row.status === "Intesting") {
          return <span className={css.statusTesting}>{row.status}</span>;
        } else if (row.status === "Done") {
          return <span className={css.statusDone}>{row.status}</span>;
        } else if (row.status === "Close") {
          return <span className={css.statusClose}>{row.status}</span>;
        } else if (row.status === "Inprogress") {
          return <span className={css.statusInprogress}>{row.status}</span>;
        } else if (row.status === "Todo") {
          return <span className={css.statusTodo}>{row.status}</span>;
        }
      },
      reorder: true,
      center: true,
    },
    {
      name: "PRIORITY",
      cell: (row) => {
        if (row.Priority === "Normal") {
          return (
            <span className={css.tablePriorityNoraml}>{row.Priority}</span>
          );
        } else if (row.Priority === "High") {
          return <span className={css.tablePriorityHigh}>{row.Priority}</span>;
        } else {
          return <span className={css.tablePriorityLow}>{row.Priority}</span>;
        }
      },
      sortable: true,
      reorder: true,
      center: true,
      width: "150px",
    },
    {
      name: "DEV TYPE",
      cell: (row) => {
        if (row.dev_Type
          === "Frontend") {
          return (
            <span className={css.statusTodo}>{row.dev_Type}</span>
          );
        } else if (row.dev_Type === "Backend") {
          return <span className={css.statusInprogress}>{row.dev_Type}</span>;
        }
      },
      sortable: true,
      reorder: true,
      center: true,
      width: "150px",
    },
    {
      name: "ACTIONS",
      reorder: true,
      center: true,
      width: "150px",
      cell: (row) => (
        <>
          <div className={css.tableIcon}>
            <VisibilityIcon onClick={(e) => handleDescription(row)} />
            <EditIcon onClick={(e) => handleEditTask(row)} />
          </div>
        </>
      ),
    },
  ];
  // Table Styling
  const customStyles = {
    header: {
      style: {
        fontSize: "32px",
        color: "#283686",
      },
    },
    subHeader: {
      style: {
        marginBottom: "10px",
      },
    },

    headRow: {
      style: {
        fontSize: "16px",
      },
    },
    headCells: {
      style: {
        color: "#3a3a3a",
        fontSize: "16px",
        fontWeight: "600",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        boxSizing: "inherit",
        borderBottomColor: "#FFFFFF",
        borderRadius: "25px",
        outline: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
        justifyContent: "flex-start",
      },
    },
  };

  // Table Items End
  return (
    <>
      <div className={css.ticket_bottom}>
        <DataTable
          subHeader
          fixedHeader
          subHeaderComponent={
            <>
              <Filtering
                setStarting_Date={setStarting_Date}
                setEnding_Date={setEnding_Date}
                setStatus={setStatus}
                setSearchData={setSearchData}
                status={status}
                statuses={statuses}
                placeholders={placeholders}

              />
            </>
          }
          fixedHeaderScrollHeight="500px"
          columns={colums}
          data={searchFilterData}
          pagination
          highlightOnHover
          pointerOnHover
          customStyles={customStyles}

        />
      </div>
    </>
  );
};

export default TaskTable;
