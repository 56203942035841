import React from "react";
import Style from "../styles/select.module.scss";
const SelectInput = (props) => {
  const {
    option,
    readonly,
    value,
    onChange,
    register,
    label,
    check,
    // error,
    placeholder,
    disabled,
    selected
  } = props;
  return (
    <>
      <div className={Style.select}>
        <select
          {...(register ? register(label, check) : {})}
          className={Style.mySelectArrow}
          readonly={readonly}
          value={value}
          onChange={onChange}
          disabled={disabled}
        >
          {/* <optgroup> */}
          <option value="" disabled hidden>
            {placeholder}
          </option>
          {option?.map((data) => (
            <option value={data}>{data}</option>
          ))}
        </select>
      </div>
    </>
  );
};

export default SelectInput;
