import { createSlice } from "@reduxjs/toolkit";
import {
  AddEmployeeAction,
  EmployeeGetByIdAction,
  EmployupdateAction,
  GetEmployeeData,
} from "../actions/Employees";

export const EmployeeSlice = createSlice({
  name: "employee",
  initialState: {
    employData: [],
    gettAllData: {},
    employeeDataById: {},
  },

  extraReducers: {
    // Add Employ
    [AddEmployeeAction.fulfilled]: (state, { payload }) => {
      state.message = payload;
      return state;
    },
    // [AddEmployeeAction.pending]: (state) => {
    //   state.isFetching = true;
    //   console.log(state.message);
    // },
    // [AddEmployeeAction.rejected]: (state, { payload }) => {
    //   state.errorMessage = payload.message;
    //   console.log(state.message);
    // },
    // Get All Employee

    [GetEmployeeData.fulfilled]: (state, { payload }) => {
      state.gettAllData = payload;
      return state;
    },
    [GetEmployeeData.pending]: (state) => {
      state.isFetching = true;
    },
    [GetEmployeeData.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },

    // Employee Get By ID
    [EmployeeGetByIdAction.fulfilled]: (state, { payload }) => {
      state.employeeDataById = payload;
      return state;
    },
    [EmployeeGetByIdAction.pending]: (state, { payload }) => {
      state.isFetching = true;
    },
    [EmployeeGetByIdAction.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
    },

    // Update Employ
    [EmployupdateAction.fulfilled]: (state, { payload }) => {
      state.employData = payload;
      return state;
    },

    [EmployupdateAction.pending]: (state) => {
      state.fatching = true;
    },
    [EmployupdateAction.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },
  },
});

export const employSelector = (state) => state.employee;
