import React, { useRef, useState, useEffect } from "react";

// css
import css from "../styles/registration.module.scss";
import "../styles/global.scss";

// Material Components
import { Alert, Box, Button, CircularProgress, Grid } from "@mui/material";

// Components
import SwipeBtn from "../components/SwipeBtn";
import Input from "../components/sharedComponents/input";

// From
import { useForm } from "react-hook-form";

// Redux
import { userSelector } from "../redux/slice/UserSlice";
import SignupUser from "../redux/actions/signupAction";
import { useSelector, useDispatch } from "react-redux";

// Framer motion
import { motion } from "framer-motion";
import AlertComponent from "../components/Alert";
import { useNavigate } from "react-router-dom";
// Socket
import { io } from "socket.io-client";

const Signup = () => {
  const dispatch = useDispatch();
  const registerData = useSelector(userSelector);

  const [showAlert, setShowAlert] = useState(false);
  const [buttonClick, setButtonClick] = useState(false);
  const [loader, setLoader] = useState(null);

  // Socket Io Connection
  const [socket, setSocket] = useState(null);
  useEffect(() => {
    setSocket(io("http://localhost:8080/"));
  }, []);

  const navigate = useNavigate();
  const response = registerData.message.status?.success;

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    mode: "onTouched",
  });

  const Password = useRef({});
  Password.current = watch("password", "");

  useEffect(() => {
    const { message } = registerData;
    if (Object.keys(message).length > 0 && buttonClick) {
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
        setLoader(null);
      }, 2000);
    }
  }, [registerData]);

  const onSubmit = (Data) => {
    setLoader(response);

    setButtonClick(true);
    dispatch(SignupUser({ Data }))
      .unwrap()
      .then(() => {
        if(registerData?.message?.status?.success === true){
          socket?.emit("sendNotification", {
            firstName: Data?.first_name,
            lastName: Data?.last_name,
            email: Data?.email,
            phone: Data?.phone_no,
            type: "Register",
          });
        }
        
      }).catch((e)=>{

      })
    reset();
  };

  return (
    <>
      <SwipeBtn />

      <motion.div
        container
        className={css.signup}
        initial={{ scale: 0 }}
        whileInView={{ scale: 1 }}
        exit={{ scale: 0 }}
        transition={{ duration: 0.4, type: "linear" }}
      >
        <Grid item className={css.signup_left}>
          <h1>Welcome!</h1>
          <h4>Enter your details and start journey with us</h4>
        </Grid>
        <Grid item className={css.signup_right}>
          <div className={css.signup_top}>
            <h2>Apply as Employee</h2>
          </div>
          <div className={css.signup_bottom}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                marginBottom={3}
                display="flex"
                gap="20px"
                sx={{
                  flexDirection: {
                    xs: "column", // 0
                    sm: "row", // 600
                  },
                }}
              >
                <Box className={css.inputerror}>
                  <Input
                    label="first_name"
                    register={register}
                    name="First Name"
                    check={{
                      required: "First name is Required",
                      pattern: /^[A-Za-z]+$/i,
                      minLength: 3,
                    }}
                    type="text"
                    placeholder="First Name *"
                    errors={errors}
                  />
                </Box>
                <Box className={css.inputerror}>
                  <Input
                    label="last_name"
                    register={register}
                    name="Last Name"
                    check={{
                      required: "Last Name is Required",
                      pattern: /^[A-Za-z]+$/i,
                      minLength: 3,
                    }}
                    type="text"
                    placeholder="Last Name *"
                    errors={errors}
                  />
                </Box>
              </Box>
              <Box marginBottom={3} className={css.inputerrors}>
                <Input
                  label="email"
                  register={register}
                  name="Email"
                  check={{
                    required: "Email is Required",
                    pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  }}
                  type="text"
                  placeholder="Your Email *"
                  errors={errors}
                />
              </Box>
              <Box
                marginBottom={3}
                display="flex"
                gap="20px"
                sx={{
                  flexDirection: {
                    xs: "column", // 0
                    sm: "row", // 600
                  },
                }}
              >
                <Box className={css.inputerror}>
                  <Input
                    label="password"
                    register={register}
                    name="Password"
                    check={{ required: "Password is Required", minLength: 8 }}
                    type="password"
                    placeholder="password *"
                    errors={errors}
                  />
                </Box>
                <Box className={css.inputerror}>
                  <Input
                    label="ConfirmPassword"
                    register={register}
                    name="ConfirmPassword"
                    check={{
                      required: "ConfirmPassword is Required",
                      validate: (value) =>
                        value === Password.current ||
                        "The passwords do not match",
                    }}
                    type="password"
                    placeholder="confirm password *"
                    errors={errors}
                  />
                </Box>
              </Box>
              <Box marginBottom={3} className={css.inputerrors}>
                <Input
                  label="phone_no"
                  register={register}
                  name="Phone No"
                  check={{
                    required: "Phone No is Required",
                    minLength: 11,
                  }}
                  type="number"
                  placeholder="Mobile Number *"
                  errors={errors}
                />
              </Box>
              {/* <Box
                marginBottom={3}
                display="flex"
                alignItems="center"
                gap="20px"
                sx={{
                  flexDirection: {
                    xs: "column", // 0
                    sm: "row", // 600
                  },
                }}
              >
                <Box className={css.inputerror}>
                  <Input
                    label="no_of_employees"
                    register={register}
                    name="no_of_employees"
                    check={{
                      required: "no_of_employees is required",
                      valueAsNumber: true,
                    }}
                    type="number"
                    placeholder="No of Employees *"
                    errors={errors}
                  />
                </Box>
                <Box className={css.signupSelect}>
                  <FormControl variant="filled" fullWidth size="small">
                    <SelectInput
                      seleted
                      option={Data}
                      name="role"
                      placeholder="Your Role/Designation"
                      register={register}
                      label="role"
                      errors={errors}
                    />
                  </FormControl>
                </Box>
              </Box>  */}

              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                marginTop={3}
                marginBottom={1}
              >
                <Button
                  variant="contained"
                  size="small"
                  type="submit"
                  className={css.button}
                  sx={{
                    borderRadius: "16px",
                    backgroundColor: "#283686",
                    width: "160px",
                    textTransform: "capitalize",
                    fontSize: "0.9rem",
                  }}
                  disabled={loader !== null}
                  // onClick={handleLoader}
                >
                  {loader !== null ? (
                    <CircularProgress color="inherit" className={css.loader} />
                  ) : (
                    "Register"
                  )}
                </Button>
              </Box>
            </form>
          </div>
        </Grid>
      </motion.div>

      {showAlert && (
        <AlertComponent
          response={response}
          severity={response ? "success" : "error"}
          message={
            response
              ? registerData.message.data.message
              : registerData.message.error.message
          }
          classs={response ? "loginAlertSuccess" : "loginAlertError"}
        />
      )}
    </>
  );
};

export default Signup;
