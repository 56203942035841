import React, { useEffect, useState } from "react";
import Style from "../../styles/projectTable.module.scss";
// Material Icon
import EditIcon from "@mui/icons-material/Edit";
import WidgetsIcon from "@mui/icons-material/Widgets";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
// Table
import DataTable from "react-data-table-component";
import { TextField } from "@mui/material";
//filter Component
import Filtering from "../Filtering";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { GetAllProjects } from "../../redux/actions/GetProjectsAction";
import { FilterProjects } from "../../redux/actions/FilterAction";
const ShowAllProject = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allProject = useSelector((state) => state.ProjectCreate);
  const searchFilterData = useSelector(
    (state) => state.getFilterData?.FilterProject?.data?.project
  );
  // const projects = allProject.projectData.data?.project;

  // Filter STates
  const [filterData, setFilterData] = useState();
  const [tableData, setTableData] = useState(false);
  const [editState, setEditState] = useState(false);

  // fetch Data from filtering component
  const statuses = [["All", "Completed", "Ongoing", "Pending", "Canceled"]];
  const [start_date, setStarting_Date] = useState("");
  const [end_date, setEnding_Date] = useState("");
  const [searchData, setSearchData] = useState("");
  const [status, setStatus] = useState({ statusType: "" });

  const placeholders = ["Select Status"];

  // Table dec
  const [tabledec, settabledec] = useState([]);
  const [decBox, setDecBox] = useState(false);
  const handleclick = (e) => {
    settabledec(e);
  };
  useEffect(() => {
    dispatch(
      FilterProjects({
        status: status.statusType === "All" ? "" : status.statusType,
        searchData,
        start_date: start_date ? new Date(start_date) : "",
        end_date: end_date ? new Date(end_date) : "",
      })
    );
  }, [status, searchData, start_date, end_date]);
  console.log(status, searchData, start_date, end_date, "status")
  // useEffect(() => {
  //   if (searchFilterData) {
  //     setTableData(true);
  //     setFilterData(searchFilterData);
  //   }
  // }, [searchFilterData]);

  useEffect(() => {
    dispatch(GetAllProjects());
  }, []);

  const handleEdit = (e) => {
    setEditState(true);
    navigate(`edit/${e.id}`);
  };

  // Project Description
  const handleDescription = (e) => {
    navigate(`description/${e.id}`);
  };

  const colums = [
    {
      cell: () => <WidgetsIcon style={{ fill: "#283686" }} />,
      sortable: false,
      width: "56px",
    },

    {
      name: "TITLE",
      // 28 dec working h
      selector: (row) => row.project_name,
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "15px",
        fontWeight: 600,
      },
      center: true,
    },

    {
      name: "ASSIGNEES",
      selector: (row) => (
        <>
          {row?.users.slice(0, 3).map((value) => (
            // value.user_projects.status === "Billed" &&
            <>
              <p
                style={{
                  backgroundColor: `hsla(${Math.random() * 360}, 50%, 50%, 10)`,
                  color: "white",
                  borderRadius: "5px",
                  padding: "5px 5px",
                  margin: "5px 0",
                  textAlign: "center",
                }}
              >
                {value.first_name} {value.last_name}
              </p>
            </>
          ))}
          {row?.users.length > 3 && (
            <p style={{ color: "blue", textAlign: "center", margin: "7px 0" }}>
              see more
            </p>
          )}
          {row?.users.length === 0 && <p>No Assignees</p>}
        </>
      ),

      sortable: true,
      reorder: true,
      width: "250px",
      center: true,
    },
    {
      name: "START DATE",
      selector: (row) =>
        new Date(row.start_date).getDate() +
        "-" +
        new Date(row.start_date).toLocaleString("en-us", { month: "short" }) +
        "-" +
        new Date(row.start_date).getFullYear(),
      sortable: true,
      reorder: true,
    },

    {
      name: "END DATE",
      selector: (row) =>
        new Date(row.end_date).getDate() +
        "-" +
        new Date(row.end_date).toLocaleString("en-us", { month: "short" }) +
        "-" +
        new Date(row.end_date).getFullYear(),
      sortable: true,
      reorder: true,
    },
    {
      name: "STATUS",
      cell: (row) => {
        if (row.status === "Completed") {
          return (
            <span
              style={{
                color: "white",
                fontWeight: "600",
                padding: "5px",
                borderRadius: "3px",
                backgroundColor: "darkgreen",
              }}
            >
              {row.status}
            </span>
          );
        } else if (row.status === "Ongoing") {
          return (
            <span
              style={{
                color: "white",
                padding: "5px",
                fontWeight: "600",
                borderRadius: "3px",
                backgroundColor: "rgb(9, 202, 9)",
              }}
            >
              {row.status}
            </span>
          );
        } else if (row.status === "Pending") {
          return (
            <span
              style={{
                color: "brown",
                padding: "5px",
                borderRadius: "3px",
                backgroundColor: "yellow",
                fontWeight: "600",
              }}
            >
              {row.status}
            </span>
          );
        } else {
          return (
            <span
              style={{
                backgroundColor: "red",
                color: "white",
                fontWeight: "600",
                padding: "5px",
                borderRadius: "3px",
              }}
            >
              {row.status}
            </span>
          );
        }
      },
    },

    {
      name: "ACTIONS",
      reorder: true,
      center: true,
      cell: (row) => (
        <>
          <div className={Style.tableIcon}>
            <VisibilityIcon onClick={(e) => handleDescription(row)} />
            <EditIcon onClick={(e) => handleEdit(row)} />
          </div>
        </>
      ),
    },
  ];

  const customStyles = {
    header: {
      style: {
        fontSize: "32px",
        color: "#283686",
      },
    },

    headRow: {
      style: {
        // borderBottom: "1px solid #555",
        fontSize: "16px",
      },
    },
    headCells: {
      style: {
        color: "#3a3a3a",
        fontSize: "16px",
        fontWeight: "600",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        borderRadius: "25px",
        outline: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  // Table Items End
  return (
    <>
      <div className={Style.ticket_bottom}>
        <div
          className={`${Style.tabledec} + ${decBox ? Style.tabledecActive : ""
            }`}
        >
          <div className={Style.tabledec_wrapper}>
            <div className={Style.close}>
              <CloseIcon onClick={() => setDecBox(false)} />
            </div>
            <TextField
              label="Table ID"
              defaultValue="Hello World"
              InputProps={{
                readOnly: true,
              }}
              value={tabledec.id}
            />
            <TextField
              label="Table Subject"
              defaultValue="Hello World"
              InputProps={{
                readOnly: true,
              }}
              value={tabledec.subject}
            />
          </div>
        </div>

        <DataTable
          subHeader
          fixedHeader
          subHeaderComponent={
            <>
              <Filtering
                setStarting_Date={setStarting_Date}
                setEnding_Date={setEnding_Date}
                setStatus={setStatus}
                setSearchData={setSearchData}
                status={status}
                statuses={statuses}
                placeholders={placeholders}
              />
            </>
          }
          fixedHeaderScrollHeight="500px"
          columns={colums}
          data={searchFilterData}
          pagination
          highlightOnHover
          pointerOnHover
          customStyles={customStyles}
          onRowClicked={handleclick}
        />
      </div>
    </>
  );
};

export default ShowAllProject;
