import React from "react";
import { Link } from "react-router-dom";
// css
import css from "../styles/goback.module.scss";
// material Icon
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
const GoBack = ({ link }) => {
  return (
    <>
      <div className={css.goBack}>
        <Link to={link}>
          <span>
            <KeyboardBackspaceIcon /> Go Back
          </span>
        </Link>
      </div>
    </>
  );
};

export default GoBack;
