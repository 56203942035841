import React from 'react'
import Style from '../styles/displayProject.module.scss';
import { useState } from "react";
import { useEffect } from "react";

// Css
import css from "../styles/tickets.module.scss";

// react Links
import { Link } from "react-router-dom";

// Api
import axios from "axios";

// Material Icon
import AddIcon from "@mui/icons-material/Add";

// Components
import ProjectTable from '../components/Tables/ProjectTable'
const DisplayProject = () => {

  useEffect(() => {

  }, []);
  return (
    <>
      <div className={Style.tickets}>
        <div className={Style.ticket_wrapper}>
          {/* Tickets Top  */}
          <div className={Style.ticket_top}>
            <div className={Style.ticket_left}>
              <h1>Projects</h1>
              <h5>
                <Link to="/">Dashboard</Link> / Project
              </h5>
            </div>
            <div className={Style.ticket_right}>
              <Link to='/projects/add'>
                <span>
                  <AddIcon />
                </span>
                Add Project
              </Link>
            </div>
          </div>

          {/* Table Here */}
          <ProjectTable />
        </div>
      </div>
    </>
  )
}

export default DisplayProject