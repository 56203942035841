import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import RoutingPath from "./RoutingPath";
import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";

// Error Route
import Error404 from "../containers/Error404";
import Error401 from "../containers/Error401";

// Public Routes Pages
import Login from "../containers/Login";
import Signup from "../containers/Signup";
import ForgetPassword from "../containers/ForgetPassword";

// Page Not Found
import LayoutRoute from "./LayoutRoute";
//Routing Path
import RoutingPath from "./RoutingPath";
import ResetPassword from "../containers/ResetPassword";

const Routing = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Public Route */}
          <Route element={<LayoutRoute />} path="/">
            <Route element={<PublicRoutes />}>
              <Route element={<Login />} path="/" />
              <Route element={<Login />} path="login" />
              <Route element={<Signup />} path="signup" />
              <Route element={<ForgetPassword />} path="forgetpass" />
              <Route element={<ResetPassword />} path="forgetpass/:id/:token" />
            </Route>

            {/* Private Route */}
            {RoutingPath.map(({ path, Component, roles }) => {
              return (
                <Route element={<PrivateRoutes allowedRoles={roles} />}>
                  <Route path={path} element={<Component />} />;
                </Route>
              );
            })}
            <Route element={<Error401 />} path="unauthorized" />
            <Route path="*" element={<Error404 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routing;
