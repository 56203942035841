import React, { useState, useEffect } from "react";
// Css
import css from "../styles/projects.module.scss";
// react Links
import { Grid, CircularProgress, Button } from "@mui/material";
import Input from "../components/sharedComponents/input";
import { useForm, Controller } from "react-hook-form";
import { Box } from "@mui/system";
import AddProjectAction from "../redux/actions/AddProjectAction";
import ProjectUpdateAction from "../redux/actions/ProjectUpdateAction";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import makeAnimated from "react-select/animated";
import SelectInput from "../components/selectInput";

import { Link } from "react-router-dom";
import { userSelector } from "../redux/slice/UserSlice";
import { GetProject } from "../redux/actions/GetProjectsAction";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import RichTextEditor from "../components/textarea_component/RichTextEditor";
import GetAllUser from "../redux/actions/GetAllUsersAction";
import { ProjectCreate } from "../redux/slice/ProjectSlice";
import AlertComponent from "../components/Alert";
const ProjectsDb = () => {
  const { id } = useParams();
  const paramId = id;
  const [newassign, setAssignee] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [buttonClick, setButtonClick] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [response, setResponse] = useState(false);
  const [loader, setLoader] = useState(null);
  const { register, handleSubmit, setValue, control } = useForm();

  const animatedComponents = makeAnimated();
  const dispatch = useDispatch();
  const getAllUser = useSelector(userSelector);
  const project = useSelector(ProjectCreate);
  const projectDetailById = project.projectDataById.data?.project;
  const allStatus = ["Completed", "Ongoing", "Pending", "Canceled"];
  const navigate = useNavigate();

  useEffect(() => {
    if (buttonClick) {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setResponse(false);
        setLoader(null);
        navigate("/projects");
      }, 1500);
    }
  }, [buttonClick]);

  useEffect(() => {
    if (paramId) {
      dispatch(GetProject({id:paramId}));
    }
    if (id) {
      let assignees = [];
      setValue("project_name", projectDetailById?.project_name);
      setValue("project_description", projectDetailById?.project_description);
      setValue("status", projectDetailById?.status);
      const startDate = projectDetailById?.start_date
        ? new Date(projectDetailById?.start_date)
        : new Date();
      const endDate = projectDetailById?.end_date
        ? new Date(projectDetailById?.end_date)
        : new Date();
      projectDetailById?.users.map((item) =>
        assignees.push({
          label: item?.first_name + " " + item?.last_name,
          value: item?.id,
          key: item?.id,
        })
      );
      setValue("assigne", assignees);
      setValue("start_date", startDate);
      setValue("end_date", endDate);
    }
  }, [JSON.stringify(projectDetailById)]);

  useEffect(() => {
    dispatch(GetAllUser());
  }, [JSON.stringify(getAllUser)]);

  const onSubmitForm = (Data) => {
    const updatedData = { ...Data };
    const { assigne } = updatedData;
    if (assigne && assigne.length > 0) {
      const keys = assigne.map(({ key }) => key);
      Data = { ...updatedData, assigne: keys };
    }
    setButtonClick(true);
    id
      ? dispatch(ProjectUpdateAction({ id, Data })).then(() => {
        setAlertMessage("Project Updated Successfully");
        setResponse(true);
        setLoader(response);
      })
      : dispatch(AddProjectAction({ Data })).then(() => {
        setAlertMessage("Project Add Successfully");
        setLoader(response);
      });
  };
  console.log(allStatus[1], "allStatus");
  return (
    <>
      <div className={css.project}>
        <div className={css.project_wrapper}>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Grid
              container
              space={2}
              className={css.project_name_container}
              sx={{ justifyContent: "flex-start", alignItems: "flex-start" }}
            >
              <Grid item xs={6} className={css.sub_container}>
                <Box display="inline">
                  <h1 display="inline" className={css.heading_Box}>
                    {response ? "Update Update" : "Add Project"}
                  </h1>
                </Box>
                <Input
                  type="text"
                  placeholder="Enter Project Name...."
                  label="project_name"
                  name="project_name"
                  register={register}
                  className={css.project_input}
                  style={{ fontSize: "1.2rem" }}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="project_description"
                  control={control}
                  render={({ field }) => {
                    let value = field.value ? field.value : "";
                    return (
                      <RichTextEditor
                        onChange={(e) =>
                          field.onChange(
                            e.html.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, "")
                          )
                        }
                        value={value}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid
                container
                space={2}
                className={css.start_end_date_container}
              >
                <Grid item md={6} xs={12} className={css.date_container}>
                  <Box display="inline">
                    <h2 display="inline" className={css.heading_Box}>
                      Start Date:
                    </h2>
                  </Box>
                  <div className={css.calendar_div}>
                    <Controller
                      name={"start_date"}
                      control={control}
                      // defaultValue={new Date(user?.loginData?.data?.user?.dob)}
                      render={({ field }) => {
                        return (
                          <DatePicker
                            dateFormat="d MMMM, yyyy"
                            onChange={(e) => field.onChange(e)}
                            selected={field.value}
                            // onChange={(date) => setStartingDate(new Date(date).setHours(12, 0, 0, 0))}
                            placeholderText="Enter Date"
                            // showMonthDropdown
                            showYearDropdown
                            scrollableMonthYearDropdown
                            className={css.calendar}
                          />
                        );
                      }}
                    />
                  </div>
                </Grid>

                <Grid item md={6} xs={12} className={css.date_container}>
                  <Box display="inline">
                    <h2 display="inline" className={css.heading_Box}>
                      End Date:
                    </h2>
                  </Box>
                  <div className={css.calendar_div}>
                    <Controller
                      name={"end_date"}
                      control={control}
                      // defaultValue={new Date(user?.loginData?.data?.user?.dob)}
                      render={({ field }) => {
                        return (
                          <DatePicker
                            dateFormat="d MMMM, yyyy"
                            onChange={(e) => field.onChange(e)}
                            selected={field.value}
                            placeholderText="Enter Date Date"
                            // showMonthDropdown
                            showYearDropdown
                            scrollableMonthYearDropdown
                            className={css.calendar}
                          />
                        );
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                space={2}
                className={css.assign_container}
                sx={{ justifyContent: "space-between" }}
              >
                <Grid md={6} xs={12} className={css.sub_container}>
                  <Box className={css.assign_box}>
                    <h2 className={css.heading}>Project Assign:</h2>
                  </Box>
                  <div className={css.assign_div}>
                    <Controller
                      control={control}
                      // defaultValue={default_value}
                      name="assigne"
                      render={({ field }) => (
                        <>
                          {console.log("filed", field.value)}
                          <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={getAllUser?.allUsers?.data?.users.map(
                              (data) => {
                                return {
                                  label: data.first_name + " " + data.last_name,
                                  value: data.id,
                                  key: data.id,
                                };
                              }
                            )}
                            className={css.multiple_Select_Box}
                            onChange={(e) => field.onChange(e)}
                            selected={field.value}
                            value={field.value}
                            register={register}
                            label="assigne"
                            name="assigne"
                          />
                        </>
                      )}
                    />
                  </div>
                </Grid>

                <Grid md={6} xs={12} className={css.sub_container}>
                  <Box className={css.assign_box}>
                    {/* <LowPriority className="calender_icon" /> */}
                    <h2 className={css.heading}>Project Status:</h2>
                  </Box>
                  <div className={css.assign_div}>
                    <SelectInput
                      // disabled
                      check={{
                        required: "Status is Required",
                        onChange: (e) => e.target.value,
                      }}
                      register={register}
                      option={allStatus}
                      className={css.status_input}
                      name="status"
                      label="status"
                      selected={allStatus[1]}
                    />
                  </div>
                </Grid>
                <Grid xs={12} className={css.sub_container}>
                  <div className={css.button_div}>
                    {/* <Link to="/projects"> */}

                    <Button
                      type="submit"
                      className={css.button_submit}
                      disabled={loader !== null}
                    // {...{ data, reset, setValue }}
                    >
                      {loader !== null ? (
                        <CircularProgress
                          color="inherit"
                          className={css.loader}
                        />
                      ) : (
                        id ? "Edit" : "Add"
                      )}
                    </Button>

                    {/* </Link> */}
                    <Button type="button" className={css.button_cancel}>
                      <Link to="/projects">Cancel</Link>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>

      {showAlert && (
        <AlertComponent
          response={response}
          severity={"success"}
          message={alertMessage}
          classs={"loginAlertSuccess"}
        />
      )}
    </>
  );
};

export default ProjectsDb;
