export const UserShortName = ({ firstName, lastName }) => {
  let FirstName = firstName?.substring(0, 1);
  let LastName = lastName?.substring(0, 1);
  const FullName = FirstName + LastName;
  return FullName;
};
export const userRoles = {
  employee: "/dashboard",
  admin: "/dashboard",
};
