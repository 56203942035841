import React, { useState, useRef, useEffect } from "react";
// Css
import Styles from "../styles/profile.module.scss";
// Images
import profileimage from "../assets/images/Profile_logo.png";
// Material
import { Grid, CircularProgress, Button } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import AddCircleIcon from "@mui/icons-material/AddCircle";
// Components
import AddSkill from "./AddSkill";
import Input from "../components/sharedComponents/input";
import SelectInput from "../components/ProfileSelectInput";
import { Controller, useForm } from "react-hook-form";
import { UserShortName } from "../Utils.js";

// Date
import city from "../json file/city.json";
import DatePicker from "react-datepicker";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-datepicker/dist/react-datepicker.css";
// Alert
import AlertComponent from "./Alert";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../redux/slice/UserSlice";
import { ProfileUpdateAction } from "../redux/actions/ProfileAction";
import { Link } from "react-router-dom";
import "../../src/styles/calender.scss";

const Profile = () => {
  // Redux
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const data = user.userData?.data?.user;
  const id = user.userData?.data?.user?.id;
  // Skill
  const [modal, setModal] = useState(false);
  const [skillData, setSkillData] = useState([{ skill: "" }]);
  const [chipData, setChipData] = useState([]);
  const [loader, setLoader] = useState(null);

  // Alert
  const [showAlert, setShowAlert] = useState(false);
  const [buttonClick, setButtonClick] = useState(false);
  const response = user.userData.status?.success;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm();

  useEffect(() => {
    if (data) {
      const inputsField = [
        "first_name",
        "last_name",
        "email",
        "phone_no",
        "gender",
        "cnic",
        "address",
        "city",
        "marital_status",
        "alt_phone_number",
      ];
      inputsField.forEach((field) => setValue(field, data[field]));
      setValue("dob", data?.dob ? new Date(data?.dob) : new Date());
      const newSkill = data?.skills ? data?.skills : [];
      const userSkills = newSkill.map((skill) => {
        return { skill };
      });
      setChipData([...userSkills]);
    }
  }, [data]);

  useEffect(() => {
    if (buttonClick) {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setLoader(null);
      }, 2000);
    }
  }, [user]);

  const citydata = city.map((item) => {
    return item.name;
  });
  const gender = ["Male", "Female"];
  const marital = ["Single", "Married"];

  const handleModalOpen = () => {
    setModal(true);
  };

  const handleChipDelete = (index) => {
    const list = [...chipData];
    list.splice(index, 1);
    setChipData(list);
  };

  // const refOne = useRef(null);
  const onSubmitForm = (Data) => {
    const skills = [];
    chipData.map(({ skill }) => skills.push(skill));
    Data.skills = skills;
    dispatch(ProfileUpdateAction({ id, Data }));
    setLoader(response);
    setButtonClick(true);
  };
  return (
    // MAIN DIV CONTAINER
    <div className={Styles.container}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        {/* // MAIN GRID CONTAINER */}
        <Grid container className={Styles.gridcon} p="4px">
          {/* MAIN GRID ITEM */}
          <Grid item className={Styles.griditem} md={12} sm={11} xs={11}>
            {/* PROFILE IMAGE CONTAINER */}
            <Grid className={Styles.profilecon} conatainer>
              <Grid className={Styles.profileitem} item>
                <h1>
                  <UserShortName
                    firstName={data?.first_name}
                    lastName={data?.last_name}
                  />
                </h1>
              </Grid>
            </Grid>
            {/* FISRT NAME , LAST NAME CONATINER */}

            <Grid container className={Styles.namecon} spacing={1}>
              <Grid item className={Styles.nameitem} sm={5} xs={11}>
                <label htmlFor=""> First Name</label>
                <Input
                  type="text"
                  placeholder="First Name *"
                  label="first_name"
                  name="first_name"
                  register={register}
                  errors={errors}
                />
              </Grid>

              <Grid item className={Styles.nameitem} sm={5} xs={11}>
                <label htmlFor=""> Last Name</label>
                <Input
                  type="text"
                  placeholder="Last Name *"
                  label="last_name"
                  name="last_name"
                  register={register}
                  errors={errors}
                />
              </Grid>
            </Grid>

            <Grid container className={Styles.skillcon} spacing={1}>
              <Grid item className={Styles.skillitem} sm={10} xs={11}>
                <label htmlFor=""> skills</label>

                <Stack
                  direction="row"
                  spacing={1}
                  className={Styles.stack_icon}
                >
                  {chipData.map((item, index) => {
                    return (
                      <div key={index}>
                        <Chip
                          label={item.skill}
                          className={Styles.chips}
                          onDelete={() => handleChipDelete(index)}
                          color="primary"
                        />
                      </div>
                    );
                  })}

                  <AddCircleIcon
                    className={Styles.addskills}
                    onClick={handleModalOpen}
                  />
                </Stack>
              </Grid>
            </Grid>

            {/* Add Skill Box */}

            <AddSkill
              modal={modal}
              setModal={setModal}
              skillData={skillData}
              setSkillData={setSkillData}
              setChipData={setChipData}
              chipData={chipData}
            />

            {/* // EMAIL PHONE CONTAINER  */}

            <Grid container className={Styles.emailcon} spacing={1}>
              <Grid item className={Styles.emailitem} sm={5} xs={11}>
                <label htmlFor="">Email</label>
                <Input
                  type="text"
                  placeholder="abc@mail.com"
                  name="email"
                  label="email"
                  register={register}
                  errors={errors}
                  disabled
                />
              </Grid>

              <Grid item className={Styles.phoneitem} sm={5} xs={11}>
                <label htmlFor="">Phone</label>
                <Input
                  type="number"
                  placeholder="03000000000000"
                  name="phone_no"
                  label="phone_no"
                  register={register}
                />
              </Grid>
            </Grid>

            {/* nic CONTAINER  */}

            <Grid container className={Styles.niccon} spacing={1}>
              <Grid item className={Styles.genderitem} sm={5} xs={11}>
                <label htmlFor="">Gender</label>
                <div>
                  <SelectInput
                    option={gender}
                    className={Styles.genderInput}
                    name="gender"
                    label="gender"
                    register={register}
                  />
                </div>
              </Grid>
              <Grid item className={Styles.nicitem} sm={5} xs={11}>
                <label htmlFor="">Nic Number</label>
                <Input
                  type="number"
                  placeholder="42201-1234567-0"
                  name="cnic"
                  label="cnic"
                  register={register}
                />
              </Grid>
            </Grid>

            {/* Address Line CONTAINER  */}

            <Grid container className={Styles.addresscon} spacing={1}>
              <Grid item className={Styles.addressitem} sm={10} xs={11}>
                <label htmlFor="">Address Line</label>
                <Input
                  type="text"
                  placeholder="1234 Street"
                  name="address"
                  label="address"
                  register={register}
                />
              </Grid>
            </Grid>

            {/* COUNTRY birth CONTAINER  */}

            <Grid container className={Styles.countrycon} spacing={1}>
              <Grid item className={Styles.countryitem} sm={5} xs={11}>
                <label className={Styles.label}>City</label>
                <div>
                  <SelectInput
                    option={citydata}
                    className={Styles.cityinput}
                    name="city"
                    label="city"
                    register={register}
                  />
                </div>
              </Grid>

              <Grid item className={Styles.birthitem} sm={5} xs={11}>
                <label htmlFor="">Birthdate</label>
                <div className={Styles.calendar_div}>
                  <Controller
                    name={"dob"}
                    control={control}
                    render={({ field }) => {
                      return (
                        <DatePicker
                          selected={field.value}
                          // showMonthDropdown
                          showYearDropdown
                          className={Styles.calendar}
                          scrollableMonthYearDropdown
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </Grid>
            </Grid>

            {/*  MATERIAL Alternative Number CONTAINER  */}

            <Grid container className={Styles.marital_altnum} spacing={1}>
              <Grid item className={Styles.maritalitem} sm={5} xs={11}>
                <label htmlFor="">Marital Status</label>
                <div className={Styles.marital}>
                  <SelectInput
                    option={marital}
                    className={Styles.maritalinput}
                    name="marital_status"
                    label="marital_status"
                    register={register}
                  />
                </div>
              </Grid>

              <Grid item className={Styles.altNumitem} sm={5} xs={11}>
                <label htmlFor="">Alternative Phone Number</label>
                <Input
                  type="number"
                  placeholder="0310000000"
                  name="alt_phone_number"
                  label="alt_phone_number"
                  register={register}
                />
              </Grid>
            </Grid>

            {/* // BUTTON CONTAINER  */}
            <Grid container className={Styles.btncon} spacing={1}>
              <Grid item className={Styles.btnitem} sm={5} xs={11}>
                <Button type="submit" disabled={loader !== null}>
                  {loader !== null ? (
                    <CircularProgress
                      color="inherit"
                      className={Styles.loader}
                    />
                  ) : (
                    "Save Profile"
                  )}
                </Button>
                <Button>
                  <Link to="/dashboard"> Cancel</Link>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {showAlert && (
        <AlertComponent
          response={response}
          severity={response ? "success" : "error"}
          message={
            response
              ? "Profile Has Been Updated Successfully"
              : "Some Error Occured"
          }
          classs={response ? "loginAlertSuccess" : "loginAlertError"}
        />
      )}
    </div>
  );
};

export default Profile;
