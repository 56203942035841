import React, { useEffect, useState } from "react";

// css
import css from "../styles/login.module.scss";
import "../styles/global.scss";

// Images
import LoginLogo from "../assets/images/login-img.gif";

// React Links
import { Link } from "react-router-dom";

// Material Components
import {
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Button,
} from "@mui/material";

// Material Icons
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

// Components
import SwipeBtn from "../components/SwipeBtn";
import Input from "../components/sharedComponents/input";

// Form
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userSelector } from "../redux/slice/UserSlice";
import loginUser from "../redux/actions/login";

// Framer motion
import { motion } from "framer-motion";
import AlertComponent from "../components/Alert";
import { userRoles } from "../Utils";

const Login = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [buttonClick, setButtonClick] = useState(false);
  const [loader, setLoader] = useState(null);
  const navigate = useNavigate();
  const { userData } = useSelector(userSelector);
  const dispatch = useDispatch();
  const roleName = userData?.data?.user?.roles?.role_name;

  const response = userData?.status?.success;
  // const
  const {
    register,
    handleSubmit,
    formState: { errors },
    // watch,
    reset,
  } = useForm({
    mode: "onTouched",
  });

  useEffect(() => {
    const { status } = userData;
    if (buttonClick) {
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
        setLoader(null);
        // setButtonClick(false)
      }, 2000);
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.status?.success) {
      localStorage.setItem(
        "token",
        JSON.stringify({
          accessToken: userData.data.jwtToken,
          role: roleName,
        })
      );
      navigate(userRoles[roleName]);
      reset();
    }
  }, [JSON.stringify(userData)]);

  const onSubmit = (Data) => {
    setLoader(response);
    setButtonClick(true);
    dispatch(loginUser({ Data }));
  };

  return (
    <>
      <div className={css.login}>
        <SwipeBtn />
        <div className={css.login_top}>
          <h1>let's get started now !</h1>
          <h2> Login as Employee</h2>
        </div>
        <motion.div
          className={css.login_bottom}
          initial={{ scale: 0 }}
          whileInView={{ scale: 1 }}
          exit={{ scale: 0 }}
          transition={{ duration: 0.4, type: "linear" }}
        >
          <div className={css.login_main}>
            <div className={css.login_logo}>
              <img src={LoginLogo} alt="" />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={css.login_items}>
                <span>Email:</span>
                <div className={css.login_input}>
                  <div className={css.loginicon}>
                    <MailOutlineIcon />
                  </div>
                  <div className={css.login_detail}>
                    <Input
                      label="username"
                      name="Email"
                      register={register}
                      check={{
                        required: "Email is Required",
                        pattern:
                          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                      }}
                      type="text"
                      placeholder="Your Email *"
                      errors={errors}
                    />
                  </div>
                </div>
              </div>

              <div className={css.login_items}>
                <span>Password:</span>
                <div className={css.login_input}>
                  <div className={css.loginicon}>
                    <LockOpenIcon />
                  </div>
                  <div className={css.login_detail}>
                    <Input
                      label="password"
                      register={register}
                      check={{ required: "Password is Required" }}
                      type="password"
                      name="Password"
                      placeholder="password *"
                      errors={errors}
                    />
                  </div>
                </div>
              </div>

              <div className={css.login_btn}>
                <Button type="submit" disabled={loader !== null}>
                  {loader !== null ? (
                    <CircularProgress color="inherit" className={css.loader} />
                  ) : (
                    "Login"
                  )}
                </Button>
              </div>

              <div className={css.login_footer}>
                <FormControlLabel
                  className={css.login_footerIcon}
                  control={
                    <Checkbox
                      icon={<FavoriteBorderIcon />}
                      checkedIcon={<FavoriteIcon />}
                    />
                  }
                  label="Remember me"
                  {...register("rememberMe")}
                />

                <Link to="/forgetpass" underline="none" variant="subtitle2">
                  forget Password?
                </Link>
              </div>
            </form>
          </div>
        </motion.div>
      </div>

      {showAlert && (
        <AlertComponent
          response={false}
          severity={"error"}
          message={"Invalid Email & Password Please Try Again"}
          classs={"loginAlertError"}
        />
      )}
    </>
  );
};

export default Login;
