import React from "react";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";

// Css
import css from "../styles/filtering.module.scss";
import "../../src/styles/calender.scss";
import Select from "./sharedComponents/Select";
import { Button } from "@mui/material";

const Filtering = ({
    setStarting_Date,
    setEnding_Date,
    setSearchData,
    setStatus,
    status,
    statuses,
    placeholders,
}) => {
    const [inputSearch, setInputSearch] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [selectStatus, setSelectStatus] = useState(null);


    setStarting_Date(startDate);
    setEnding_Date(endDate);
    const handleSearchingData = (e) => {
        setSearchData(e.target.value);
        setInputSearch(e.target.value);
    };

    const resetField = () => {
        setInputSearch("");
        setStartDate("");
        setEndDate("");
        setSelectStatus(true);
        setSearchData("");
        setStarting_Date("");
        setEnding_Date("");
        setStatus({ devType: "", statusType: "", priority: "" });

    };
    useEffect(() => {
        setSelectStatus(false);
    }, [selectStatus])

    const handleSelect = (e, i) => {
        const keyName = i === 0 ? "statusType" : i === 1 ? "priority" : "devType";
        const value = e.target.value;
        setStatus({ ...status, [keyName]: value });
    };
    return (
        <>
            <div className={css.filtering}>
                <div className={css.filteringWrapper}>
                    <div className={css.filteringByName}>
                        <input
                            type="text"
                            placeholder="Search By Name"
                            onChange={handleSearchingData}
                            value={inputSearch}
                        />
                    </div>
                    {statuses.map((value, i) => {
                        return (
                            <div className={css.filteringBySelect}>
                                <Select
                                    option={value}
                                    placeholder={placeholders[i]}
                                    label={i}
                                    name={i}
                                    onChange={(e) => handleSelect(e, i)}
                                    selected={selectStatus}
                                    // value={selectStatus}
                                    key={i}
                                // defaultValue={"All"}
                                ></Select>
                            </div>
                        );
                    })}
                    <div className={css.filteringByDateRange}>
                        <DatePicker
                            dateFormat="d MMMM, yyyy"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            showYearDropdown
                            scrollableMonthYearDropdown
                            className={css.calender}
                            portalId="root"
                            placeholderText={"Starting Date"}
                            value={startDate}
                        />
                        <DatePicker
                            dateFormat="d MMMM, yyyy"
                            selected={endDate}
                            onChange={(date) => {
                                setEndDate(date);
                            }}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            showYearDropdown
                            scrollableMonthYearDropdown
                            className={css.calender}
                            portalId="root"
                            placeholderText={"Ending Date"}
                            value={endDate}
                        />
                    </div>
                    <div className={css.resetButton}>
                        <Button onClick={resetField}>Reset</Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Filtering;
