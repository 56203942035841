import React from "react";
import { useParams } from "react-router";
import { useEffect } from "react";

// Css
import css from "../styles/taskdescription.module.scss";

// Material Icon
import Person2Icon from "@mui/icons-material/Person2";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SnoozeIcon from '@mui/icons-material/Snooze';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import PivotTableChartOutlinedIcon from '@mui/icons-material/PivotTableChartOutlined';
// Components
import { UserShortName } from "../Utils";
import GoBack from "./GoBack";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { taskSelector } from "../redux/slice/TaskSlice";
import { GetTaskByIdAction } from "../redux/actions/TaskAction";
import DOMPurify from "dompurify";


const TaskDescription = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { getTaskById } = useSelector(taskSelector);
  const taskDescription = getTaskById?.data;
  const assigneFirstName =  getTaskById?.data?.users[0].first_name;
  const assigneLastName =  getTaskById?.data?.users[0].last_name;

  useEffect(() => {
    dispatch(GetTaskByIdAction(id));
  }, []);

  const taskData =
    new Date(taskDescription?.createdAt).getDate() +
    "-" +
    new Date(taskDescription?.createdAt).toLocaleString("en-us", {
      month: "short",
    }) +
    "-" +
    new Date(taskDescription?.createdAt).getFullYear();

  return (
    <>
      <div className={css.taskDescription}>
        <div className={css.taskDescription_Wrapper}>
          <GoBack link={"/task?project=all"} />
          <div className={css.taskDescription_Box}>
            {/* Description Top */}
            <div className={css.taskDescription_Top}>
              <div className={css.taskDescription_logo}>
                <div className={css.taskDescription_Image}>
                  <h1>
                    <UserShortName firstName={assigneFirstName} lastName={assigneLastName} />
                  </h1>
                </div>
              </div>
              <div className={css.taskDescription_Title}>
                <h2>{taskDescription?.task_title}</h2>
                <h4>
                <Person2Icon /> {`${assigneFirstName} ${assigneLastName}`}
                </h4>
              </div>
              <div className={css.taskDescription_date}>
                <h4>
                  <span>Date :</span> {taskData}
                </h4>
              </div>
            </div>

            <div className={css.taskDescription_Bottom}>
              <div className={css.taskDescription_detail_heading}>
                <h1>Task Description</h1>
              </div>
              <div className={css.taskDescriptionBox}>
                <div className={css.taskDescription_Detail}>
                  <div className={css.taskDescription_Detail_Icon}>
                    <AssignmentIcon />
                  </div>
                  <div className={css.taskDescription_Detail_Item}>
                    <span>Description</span>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          taskDescription?.task_description
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={css.taskDescription_Bottom}>
              <div className={css.taskDescription_detail_heading}>
                <h1>Other Details</h1>
              </div>
              <div className={css.taskDescription_Detail_Wrapper}>
                <div className={css.taskDescription_Detail}>
                  <div className={css.taskDescription_Detail_Icon}>
                    <SnoozeIcon />
                  </div>
                  <div className={css.taskDescription_Detail_Item}>
                    <span>Estimated Time</span>
                    <h4>{taskDescription?.estimated_time} Unit</h4>
                  </div>
                </div>

                <div className={css.taskDescription_Detail}>
                  <div className={css.taskDescription_Detail_Icon}>
                    <HourglassTopOutlinedIcon />
                  </div>
                  <div className={css.taskDescription_Detail_Item}>
                    <span>Actual Time</span>
                    <h4>{taskDescription?.actual_time}</h4>
                  </div>
                </div>

                <div className={css.taskDescription_Detail}>
                  <div className={css.taskDescription_Detail_Icon}>
                    <TrendingUpOutlinedIcon />
                  </div>
                  <div className={css.taskDescription_Detail_Item}>
                    <span>Priority</span>
                    <h4>{taskDescription?.Priority}</h4>
                  </div>
                </div>

                <div className={css.taskDescription_Detail}>
                  <div className={css.taskDescription_Detail_Icon}>
                    <InsightsOutlinedIcon />
                  </div>
                  <div className={css.taskDescription_Detail_Item}>
                    <span>Status</span>
                    <h4>{taskDescription?.status}</h4>
                  </div>
                </div>

                <div className={css.taskDescription_Detail}>
                  <div className={css.taskDescription_Detail_Icon}>
                    <ConfirmationNumberOutlinedIcon />
                  </div>
                  <div className={css.taskDescription_Detail_Item}>
                    <span>Ticket Type</span>
                    <h4>{taskDescription?.Ticket_type}</h4>
                  </div>
                </div>

                <div className={css.taskDescription_Detail}>
                  <div className={css.taskDescription_Detail_Icon}>
                    <PivotTableChartOutlinedIcon />
                  </div>
                  <div className={css.taskDescription_Detail_Item}>
                    <span>Dev Type</span>
                    <h4>{taskDescription?.dev_Type}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskDescription;
