import React from "react";
import { useState } from "react";

// Css
import css from "../styles/Customizer.module.scss";

// Images
import Horizontal from "../assets/images/horizontal.png";
import Vertical from "../assets/images/vertical.png";

// Material Icon
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import ColorizeRoundedIcon from "@mui/icons-material/ColorizeRounded";
import DoneIcon from "@mui/icons-material/Done";

const Customizer = ({
  toggle,
  settoggle,
  horizontal,
  sethorizontal,
  hidden,
  sethidden,
}) => {
  const [customizerAnimate, setCustomizerAnimate] = useState(false);
  const [colorPickerBox, setColorPickerBox] = useState(false);

  const ColorScheme = [
    {
      img: Vertical,
      title: "Orange",
    },
    {
      img: Vertical,
      title: "Light",
      link: "abc",
    },
    {
      img: Vertical,
      title: "Dark",
      link: "abc",
    },
    {
      img: Vertical,
      title: "Blue ",
      link: "abc",
    },
    {
      img: Vertical,
      title: "Maroon",
      link: "abc",
    },
  ];
  const [active, setActive] = useState(0);
  const handleVertical = () => {
    settoggle(false);
    sethorizontal(false);
    sethidden(false);
  };
  const handleHorizontal = () => {
    sethorizontal(true);
    sethidden(false);
    settoggle(false);
  };

  const handleHidden = () => {
    sethidden(true);
    sethorizontal(false);
    settoggle(false);
  };

  const Layout = [
    {
      img: Vertical,
      title: "Vertical",
      click: handleVertical,
    },
    {
      img: Horizontal,
      title: "Horizontal",
      click: handleHorizontal,
    },
    {
      img: Vertical,
      title: "Hidden",
      click: handleHidden,
    },
  ];

  const handleClick = (i) => {
    setActive(i);
    setCustomizerAnimate(false);
  };

  return (
    <>
      <div
        className={`${css.customizer} + ${
          customizerAnimate ? css.customizerActive : ""
        }`}
      >
        <div className={css.customizer_right}>
          <div
            className={css.customizer_btn}
            onClick={() => setCustomizerAnimate(!customizerAnimate)}
          >
            <SettingsIcon />
          </div>
          {/* Heading */}
          <div className={css.customizer_heading}>
            <h3>Customizer</h3>
            <h5>Customize your overview Page layout</h5>
            <div
              className={css.customizer_CloseIcon}
              onClick={() => setCustomizerAnimate(false)}
            >
              <CloseIcon />
            </div>
          </div>

          {/* Layout */}

          <div className={css.customizer_Layout}>
            <div className={css.customizer_Layout_title}>
              <h5>Layout</h5>
              <h6>Choose your layout</h6>
            </div>
            <div className={css.customizer_Layout_main}>
              {Layout.map((data, index) => {
                return (
                  <>
                    <div
                      className={css.customizer_Layout_item}
                      key={index}
                      onClick={data.click}
                    >
                      <div
                        className={`${css.customizer_Layout_img} ${
                          index == active ? css.Activehe : ""
                        }`}
                        onClick={() => handleClick(index)}
                      >
                        <img src={data.img} alt="" />
                        {index === active ? (
                          <>
                            <DoneIcon className={css.LayoutActive} />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className={css.customizer_Layout_dec}>
                        <h5>{data.title}</h5>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          {/* Color */}
          <div className={css.customizer_Layout}>
            <div className={css.customizer_Layout_title}>
              <h5>Color Scheme</h5>
              <h6>Choose Light or Dark Scheme.</h6>
            </div>
            <div className={css.customizer_Layout_main}>
              {ColorScheme.map((data, index) => {
                return (
                  <>
                    <div className={css.customizer_Layout_item} key={index}>
                      <div className={css.customizer_Layout_img}>
                        <img src={data.img} alt="" />
                      </div>
                      <div className={css.customizer_Layout_dec}>
                        <h5>{data.title}</h5>
                      </div>
                    </div>
                  </>
                );
              })}
              <div
                className={css.customizer_Layout_item}
                onClick={() => setColorPickerBox(!colorPickerBox)}
              >
                <div className={css.customizer_Layout_img}>
                  <div className={css.customizer_Gradient}>
                    <ColorizeRoundedIcon />
                  </div>
                </div>
                <div className={css.customizer_Layout_dec}>
                  <h5>Color Picker</h5>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${css.colorPicker} ${
              colorPickerBox ? css.colorPickerActive : ""
            }`}
          >
            <div className={css.colorPickerTop}>
              <h2>Color Picker </h2>
              <div
                className={css.colorPickerClose}
                onClick={() => setColorPickerBox(false)}
              >
                <CloseIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customizer;
