import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

// Css
import css from "../styles/tickets.module.scss";

// react Links
import { Link, useLocation, useParams } from "react-router-dom";

// Material Icon
import AddIcon from "@mui/icons-material/Add";

// Components
import TaskTable from "../components/Tables/TaskTable";

// Framer motion
import { motion } from "framer-motion";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllTaskByProjectIdAction,
  GetAllTasksCount,
  GetAllTasksCountForEmployee,
  GetCountById,
} from "../redux/actions/TaskAction";
import { taskSelector } from "../redux/slice/TaskSlice";
import { userSelector } from "../redux/slice/UserSlice";

const Tickets = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("project");

  // Redux
  const dispatch = useDispatch();
  const { getAllCount } = useSelector(taskSelector);
  const { getCountById } = useSelector(taskSelector);
  const { userData } = useSelector(userSelector);
  // Role
  const role = userData.data?.user.roles.role_name;
  const userId = userData.data?.user.id;

  // For Admin
  const allTaskCount = getAllCount.data?.count;
  const allTaskSum = getAllCount.data?.sum;
  const taskByIdCount = getCountById.data?.count;
  const taskByIdSum = getCountById.data?.sum;

  useEffect(() => {
    if (id === "all") {
      if (role === "employee") {
        dispatch(GetAllTasksCountForEmployee({ id: userId }));
      } else {
        dispatch(GetAllTasksCount());
      }
    } else {
      dispatch(GetCountById({ id }));
    }
  }, [id]);

  const Count = id === "all" ? allTaskCount : taskByIdCount;
  const Sum = id === "all" ? allTaskSum : taskByIdSum;

  // Slider
  const [width, setWidth] = useState(0);
  const slider_width = useRef();
  const Slider = () => {
    setWidth(
      slider_width.current.scrollWidth - slider_width.current.offsetWidth
    );
  };

  useEffect(() => {
    Slider();
  }, [Slider]);

  return (
    <>
      <div className={css.tickets}>
        <div className={css.ticket_wrapper}>
          {/* Tickets Top  */}
          <div className={css.ticket_top}>
            <div className={css.ticket_left}>
              <h1>Tasks</h1>
              <h5>
                <Link to="/dashboard">Dashboard</Link> / Tasks
              </h5>
            </div>
            <div className={css.ticket_right}>
              <Link to={`/task/add/${id}`}>
                <span>
                  <AddIcon />
                </span>
                Add Tasks
              </Link>
            </div>
          </div>
          {/* Tickets Bottom  */}
          <div className={css.ticket_slider}>
            <motion.div
              className={css.ticket_boxs}
              ref={slider_width}
              whileTap={{ cursor: "grabbing" }}
              drag="x"
              dragConstraints={{ right: 0, left: -width }}
              whileHover={{ cursor: "grabbing" }}
            >
              <motion.div className={css.ticket_boxRound}>
              <div className={css.ticket_boxRound_wrapper}>
                <h3>All Task</h3>
                <span>{Sum}</span>
              </div>
              </motion.div>
              {Count?.map((items, i) => {
                const redColor = items.key === "Block" || items.key === "Close";
                const BlueColor = items.key === "Done";
                return (
                  <>
                    <motion.div className={css.ticket_box} key={i}>
                      <div className={css.ticket_box_dec}>
                        <h3>{items.key}</h3>
                        <span
                          style={{
                            color:
                              (redColor && "red") || (BlueColor && "#283686"),
                          }}
                        >
                          {Sum > 0
                            ? Math.round(100 * (items.value / Sum)) + "%"
                            : "0%"}
                        </span>
                      </div>
                      <div className={css.ticket_box_value}>
                        <span>{items.value}</span>
                      </div>
                      <div className={css.ticket_box_bar}>
                        {Sum > 0 && (
                          <div
                            style={{
                              backgroundColor:
                                (redColor && "red") || (BlueColor && "#283686"),
                              width:
                                Math.round(100 * (items.value / Sum)) + "%",
                            }}
                          ></div>
                        )}
                      </div>
                    </motion.div>
                  </>
                );
              })}
            </motion.div>
          </div>
          {/* Table Here */}
          <TaskTable id={id} />
        </div>
      </div>
    </>
  );
};

export default Tickets;
