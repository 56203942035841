import React, { useState } from "react";

// css
import css from "../styles/forgetpassword.module.scss";

// Material Icons
import LockOpenIcon from "@mui/icons-material/LockOpen";

// React Link
import { Link } from "react-router-dom";
//useform
import { useForm } from "react-hook-form";
// Framer motion
import { motion } from "framer-motion";
//input
import Input from "../components/sharedComponents/input";
// axios
import axios from "axios";
// import AlertComponent from "../components/Alert";

const ForgetPassword = () => {
  const [message, setMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    // watch,
    reset,
  } = useForm({
    mode: "onTouched",
  });

  const onSubmit = async (Data) => {
    const email = Data.Email;
    try {
      const res = await axios.post("http://localhost:8080/forgetpassoword", {
        email,
      });

      const data = await res.data;
      if (data.status.code === 201) {
        setMessage(data.message);
        reset();
      } else {
        setMessage(data.error.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <motion.div
      className={css.forgetpass}
      initial={{ scale: 0 }}
      whileInView={{ scale: 1 }}
      exit={{ scale: 0 }}
      transition={{ duration: 0.4, type: "linear" }}
    >
      <div className={css.forget_left}>
        <h1>Welcome!</h1>
        <h4>Enter your details and start journey with us</h4>
      </div>
      <div className={css.forget_right}>
        <span
          style={{
            color: "green",
            fontSize: "18px",
            textTransform: "capitalize",
          }}
        >
          {message && message}
        </span>
        <div className={css.forget_top}>
          <h1>Forget your password?</h1>
        </div>
        <div className={css.forget_bottom}>
          <div className={css.forget_bottom_wrapper}>
            <div className={css.forget_details}>
              <span>
                <LockOpenIcon />
              </span>
              <p>
                Enter your email address and we will send you a link to reset
                your pasword
              </p>
            </div>

            <div className={css.forget_main}>
              <div className={css.forget_input}>
                <span>Email Address</span>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <Input
                      label="Email"
                      name="Email"
                      register={register}
                      check={{
                        required: "Email is Required",
                        pattern:
                          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                      }}
                      type="email"
                      placeholder="Your Email *"
                      errors={errors}
                    />
                  </div>

                  <button
                    style={{
                      marginTop: "20px",
                      padding: "10px 10px 10px 10px",
                      width: "100%",
                    }}
                  >
                    Send
                  </button>
                </form>
              </div>

              <div className={css.forget_footer}>
                <span>Don't have an account ?</span>
                <button>
                  <Link to="/signup">Create an Account</Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ForgetPassword;
