import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../config/api_url";
import { getData } from "../../config/AxiosFunction";
export const FilterProjects = createAsyncThunk(
    "filterProjects",
    async ({ status, searchData, start_date, end_date }) => {
        const responseData = await getData(
            `${api}projects?filter=${status}&search=${searchData}&start_date=${start_date}&end_date=${end_date}`,
            "get"
        );
        return responseData;
    }
);

export const FilterEmployees = createAsyncThunk(
    "filterEmployees",
    async ({ status, searchData, start_date, end_date }) => {
        // debugger
        const responseData = await getData(
            `${api}users?filter=${status}&search=${searchData}&start_date=${start_date}&end_date=${end_date}`,
            "get"
        );
        return responseData;
    }
);

export const FilterTasks = createAsyncThunk(
    "filterTasks",
    async ({ id,userId, searchData, status, priority, devType, start_date, end_date }) => {
        // debugger
        const responseData = await getData(
            `${api}tasks?id=${id}&userid=${userId}&search=${searchData}&filterpriority=${priority}&filterstatus=${status}&filterdevtype=${devType}&start_date=${start_date}&end_date=${end_date}`,
            "get"
        );
        console.log(id,userId, "ididee");
        return responseData;
    }
);
